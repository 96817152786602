import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

// Constants for timeout settings
const INACTIVITY_TIMEOUT = 120 * 60 * 1000; // 2 hour in milliseconds
const ACTIVITY_EVENTS = [
  'mousedown',
  'mousemove',
  'keydown',
  'scroll',
  'touchstart',
  'click',
  'input',
  'change',
  'submit',
  'focus',
  'visibilitychange', // Add visibility change event
  'resume',           // Add resume event for mobile
  'pageshow'          // Add pageshow event for iOS
];

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Initialize with the actual localStorage value
    return Boolean(localStorage.getItem("userId"));
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const inactivityTimerRef = useRef(null);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    localStorage.removeItem("userId"); 
    localStorage.removeItem("userName"); 
    localStorage.removeItem("email"); 
    setIsLoggedIn(false);
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
      inactivityTimerRef.current = null;
    }
    navigate("/login");
  }, [navigate]);

  const resetInactivityTimer = useCallback(() => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }

    if (isLoggedIn) {
      inactivityTimerRef.current = setTimeout(() => {
        console.log('Auto-logout due to inactivity');
        logout();
      }, INACTIVITY_TIMEOUT);
    }
  }, [isLoggedIn, logout]);

  const login = useCallback(async (userId, userName, email) => {
    localStorage.setItem("userId", userId); 
    localStorage.setItem("userName", userName); 
    localStorage.setItem("email", email); 
    setIsLoggedIn(true);
    resetInactivityTimer();
    navigate("/home", { replace: true });
  }, [navigate, resetInactivityTimer]);

  // Initialize auth state and handle visibility changes
  useEffect(() => {
    const checkAuth = () => {
      const userId = localStorage.getItem("userId");
      setIsLoggedIn(Boolean(userId));
      setIsInitialized(true);
    };

    // Initial check with a small delay to ensure proper initialization
    const initTimeout = setTimeout(() => {
      checkAuth();
    }, 100);

    // Handle visibility changes
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkAuth();
      }
    };

    // Handle page show (iOS/Safari)
    const handlePageShow = () => {
      checkAuth();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('pageshow', handlePageShow);
    window.addEventListener('focus', checkAuth);

    return () => {
      clearTimeout(initTimeout);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('pageshow', handlePageShow);
      window.removeEventListener('focus', checkAuth);
    };
  }, []);

  // Handle activity monitoring
  useEffect(() => {
    if (!isLoggedIn) return;

    const resetTimer = () => resetInactivityTimer();
    
    ACTIVITY_EVENTS.forEach(event => {
      window.addEventListener(event, resetTimer);
    });

    resetInactivityTimer(); // Initial timer setup

    return () => {
      ACTIVITY_EVENTS.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
    };
  }, [isLoggedIn, resetInactivityTimer]);

  const value = React.useMemo(() => ({
    isLoggedIn,
    isInitialized,
    login,
    logout,
  }), [isLoggedIn, isInitialized, login, logout]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
