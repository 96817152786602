import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import { TfiSave } from "react-icons/tfi";
import { MdErrorOutline } from "react-icons/md";
import { PacmanLoader } from "react-spinners/";
import { toast } from "react-toastify";
import { supabase } from "../ReusableComponents/supabaseClient";
import StatusBadge from "../ReusableComponents/StatusBadge";
import moment from "moment-timezone";
import "../styles/golfPoolStyles.css";
import "./TeamViewForm.css";
import { 
  getScoreClass, 
  isPlayerSelected, 
  validateTeamSelection, 
  validateTieBreaker, 
  getChangedLineItems,
  updateTieBreakerScore,
  updateLineItem,
  // fetchUpdatedTeam
} from "./TeamViewFormHelpers";
import TeamLineItemsTable from './TeamLineItemsTable';
import { logError } from '../Utilities/errorLogger';

const TeamViewFormComponent = ({ 
  records = [], 
  isEditable: initialEditable = false, 
  poolData, 
  setSpinner, 
  setFormError, 
  setRefetchTrigger, 
  playerTournaments = [] 
}) => {
  const navigate = useNavigate();
  
  // State hooks
  const [isEditing, setIsEditing] = useState(false);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [tieBreakerScore, setTieBreakerScore] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [playerFixtures, setPlayerFixtures] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [canShowEditButton, setCanShowEditButton] = useState(false);
  const [lineItems, setLineItems] = useState([]);

  // Memoized values
  const validRecords = useMemo(() => {
    const sortedRecords = Array.isArray(records) ? 
      [...records].sort((a, b) => (a.display_order || 0) - (b.display_order || 0)) : 
      [];
    return sortedRecords;
  }, [records]);
  
  const teamId = useMemo(() => validRecords[0]?.team?.id, [validRecords]);
  // const showTieBreaker = useMemo(() => poolData?.tournament_status === "In Progress", [poolData]);

  // Initialize selectedPlayers and tieBreakerScore when records change
  useEffect(() => {
    if (!isInitialized && poolData) {
      const maxPlayers = poolData.max_players || 0;
      
      // Create an array of empty players with the correct length
      const players = new Array(maxPlayers).fill(null).map((_, index) => {
        const record = validRecords[index];
        if (record?.player) {
          return {
            id: record.player.id,
            name: record.player.player_name,
            worldRanking: record.player.world_ranking_position,
            lineItemId: record.id,
            displayOrder: record.display_order || index + 1
          };
        }
        return {
          id: "",
          name: "",
          worldRanking: 0,
          lineItemId: null,
          displayOrder: index + 1
        };
      });

      setSelectedPlayers(players);
      
      // Initialize tie breaker score from team data
      if (validRecords[0]?.team?.tie_breaker_score !== undefined) {
        setTieBreakerScore(validRecords[0].team.tie_breaker_score.toString());
      }
      
      setIsInitialized(true);
    }
  }, [poolData, validRecords, isInitialized]);

  // Update state when records change (after save)
  useEffect(() => {
    if (validRecords.length > 0 && isInitialized) {
      const maxPlayers = poolData.max_players || 0;
      
      // Use the already sorted validRecords
      const players = new Array(maxPlayers).fill(null).map((_, index) => {
        const record = validRecords[index];
        if (record?.player) {
          return {
            id: record.player.id,
            name: record.player.player_name,
            worldRanking: record.player.world_ranking_position,
            lineItemId: record.id,
            displayOrder: record.display_order
          };
        }
        return {
          id: "",
          name: "",
          worldRanking: 0,
          lineItemId: null,
          displayOrder: index + 1
        };
      });
      setSelectedPlayers(players);
      
      // Update tie breaker score if it changed
      if (validRecords[0]?.team?.tie_breaker_score !== undefined) {
        setTieBreakerScore(validRecords[0].team.tie_breaker_score.toString());
      }
    }
  }, [validRecords, poolData, isInitialized]);

  const handleSave = useCallback(async (e) => {
    e.preventDefault();

    // Validate team selection
    if (!validateTeamSelection(selectedPlayers)) {
      return;
    }

    const showTieBreakerRow = poolData.tie_breaker === "Winning Score";

    // Validate tie breaker if required
    if (!validateTieBreaker(showTieBreakerRow, tieBreakerScore)) {
      return;
    }

    setSpinner(true);
    setFormError("");

    try {
      // Get the changes first
      const changes = getChangedLineItems(selectedPlayers, validRecords);
      
      // Check if we have any changes to save
      const hasTieBreakerChange = showTieBreakerRow && tieBreakerScore !== validRecords[0]?.team?.tie_breaker_score?.toString();
      const hasPlayerChanges = changes.length > 0;

      if (!hasPlayerChanges && !hasTieBreakerChange) {
        toast.info("No changes to save", { theme: "dark" });
        setIsEditing(false);
        setSpinner(false);
        return;
      }

      const userId = localStorage.getItem("userId");
      if (!userId) {
        toast.error("User not logged in", { theme: "dark" });
        setSpinner(false);
        return;
      }

      // Create an array to hold all update promises
      const updatePromises = [];

      // Add tie breaker update if needed
      if (hasTieBreakerChange) {
        updatePromises.push(updateTieBreakerScore(teamId, tieBreakerScore));
      }
      
      // Add all player updates
      if (hasPlayerChanges) {
        changes.forEach(change => {
          updatePromises.push(updateLineItem(teamId, poolData.id, userId, change));
        });
      }

      // Wait for all updates to complete
      await Promise.all(updatePromises);

      // Only update UI after all changes are complete
      setRefetchTrigger(prev => prev + 1);
      setIsEditing(false);
      toast.success("Team updated successfully!", { theme: "dark" });
    } catch (error) {
      console.error("Error saving team:", error);
      await logError({
        filePath: 'Team/TeamViewForm.js',
        functionName: 'handleSave',
        errorType: 'Team Update Error',
        errorMessage: error.message,
        stackTrace: error.stack,
        requestData: { 
          teamId,
          selectedPlayers,
          tieBreakerScore,
          poolId: poolData.id 
        }
      });
      toast.error(error.message || "Error saving team. Please try again.", { theme: "dark" });
      setFormError(error.message || "Error saving team. Please try again.");
    } finally {
      setSpinner(false);
    }
  }, [
    selectedPlayers,
    tieBreakerScore,
    validRecords,
    teamId,
    poolData,
    setSpinner,
    setFormError,
    setRefetchTrigger
  ]);

  useEffect(() => {
    if (poolData && isInitialized) {
      setIsLoading(false);
    }
  }, [poolData, isInitialized]);

  useEffect(() => {
    const checkEditPermissions = async () => {
      if (!poolData || !validRecords[0]?.team?.owner?.id) {
        setCanShowEditButton(false);
        setIsEditing(false);
        return;
      }

      const userId = localStorage.getItem("userId");
      const isOwner = String(validRecords[0].team.owner.id) === String(userId);

      if (!isOwner) {
        setCanShowEditButton(false);
        setIsEditing(false);
        return;
      }

      // Get the cutoff time - either first tee time or midnight of start date
      const cutoffTime = poolData.first_tee_time 
        ? moment.tz(poolData.first_tee_time, poolData.time_zone)
        : moment.tz(poolData.tournament_fixture.start_date, poolData.time_zone).startOf('day');

      const currentTime = moment().tz(poolData.time_zone);
      const isBeforeStartTime = currentTime.isBefore(cutoffTime);

      // Allow editing if we're before the cutoff time
      setCanShowEditButton(isBeforeStartTime);

      if (!isBeforeStartTime && isEditing) {
        setIsEditing(false);
        toast.info("Tournament has started - editing is no longer available", { theme: "dark" });
      }
    };

    checkEditPermissions();

    const interval = setInterval(checkEditPermissions, 60000);

    return () => clearInterval(interval);
  }, [poolData, validRecords, isEditing]);

  const ownerName = useMemo(() => validRecords[0]?.team?.owner?.full_name || "Unknown", [validRecords]);

  useEffect(() => {
    if (playerTournaments.length > 0) {
      const fixtures = playerTournaments.map((item) => ({
        key: item.id,
        value: `${item.player.player_name} (${item.player.world_ranking_position})`,
        playerId: item.player.id,
        playerName: item.player.player_name,
        worldRanking: item.player.world_ranking_position,
      }));

      setPlayerFixtures(fixtures);
    }
  }, [playerTournaments]);

  const handlePlayerSelect = (index, event) => {
    const option = event.target.options[event.target.selectedIndex];
    if (!option) return;

    const playerId = Number(option.getAttribute("data-player-id"));
    const playerName = option.getAttribute("data-player-name");
    const worldRanking = Number(option.getAttribute("data-world-ranking"));

    if (playerId && playerName && !isNaN(worldRanking)) {
      // Get the current line item ID for this position
      const currentLineItemId = validRecords[index]?.id;
      const currentDisplayOrder = validRecords[index]?.display_order || index + 1;

      const newPlayer = {
        id: playerId,
        name: playerName,
        worldRanking: worldRanking,
        lineItemId: currentLineItemId,
        displayOrder: currentDisplayOrder
      };

      const newPlayers = [...selectedPlayers];
      newPlayers[index] = newPlayer;

      setSelectedPlayers(newPlayers);
    }
  };

  // Fetch initial line items data
  useEffect(() => {
    const fetchLineItems = async () => {
      if (!teamId) {
        // If no teamId, create empty line items based on max_players
        if (poolData?.max_players) {
          const emptyLineItems = new Array(poolData.max_players).fill(null).map((_, index) => ({
            id: null,
            player: null,
            tournament_position: "--",
            total_score_to_par: "--",
            players_tournament_status: null,
            display_order: index + 1
          }));
          setLineItems(emptyLineItems);
        }
        setIsLoading(false);
        return;
      }

      try {
        const { data, error } = await supabase
          .from('teamLineItem')
          .select(`
            id,
            player:player (
              player_name,
              world_ranking_position
            ),
            tournament_position,
            total_score_to_par,
            players_tournament_status,
            display_order
          `)
          .eq('team', teamId);

        if (error) throw error;

        if (data && data.length > 0) {
          setLineItems(data);
        } else if (poolData?.max_players) {
          // Create empty line items based on max_players
          const emptyLineItems = new Array(poolData.max_players).fill(null).map((_, index) => ({
            id: null,
            player: null,
            tournament_position: "--",
            total_score_to_par: "--",
            players_tournament_status: null,
            display_order: index + 1
          }));
          setLineItems(emptyLineItems);
        }
      } catch (error) {
        await logError({
          filePath: "Team/TeamViewForm.js",
          functionName: "fetchLineItems",
          errorType: "Error",
          errorMessage: error.message,
          stackTrace: error.stack,
        });
        console.error("Error fetching line items:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (poolData) {
      fetchLineItems();
    }
  }, [teamId, poolData]);

  // Handle line items updates
  const handleLineItemsUpdate = (updatedData) => {
    setLineItems(updatedData);
  };

  if (isLoading) {
    return (
      <div className="golf-pools-card">
        <div className="golf-pools-container">
          <div className="golf-pools-content">
            <div className="golf-pools-loading-container">
              <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const showTieBreakerRow = poolData.tie_breaker === "Winning Score";
  const poolName = poolData.name || "Pool";

  const resetToOriginalData = () => {
    // Map only the existing records from validRecords
    const players = validRecords.map(record => {
      if (record?.player) {
        return {
          id: record.player.id,
          name: record.player.player_name,
          worldRanking: record.player.world_ranking_position,
          lineItemId: record.id,
        };
      }
      return null;
    });

    // Fill remaining slots if needed
    while (players.length < poolData.max_players) {
      players.push({
        id: "",
        name: "",
        worldRanking: 0,
        lineItemId: null,
      });
    }

    setSelectedPlayers(players);
    
    // Reset tie breaker score
    setTieBreakerScore(validRecords[0]?.team?.tie_breaker_score || "");
  };

  const handleCancel = () => {
    resetToOriginalData();
    setIsEditing(false);
  };

  return (
    <div className="golf-pools-container">
      <div className="golf-pools-header">
        <div className="golf-pools-header-row">
          <button onClick={() => navigate(-1)} className="golf-pools-back-link left">
            <IoChevronBackOutline /> Back
          </button>
          <h1 className="golf-pools-title">{poolName}</h1>
          {canShowEditButton && (
            <button 
              onClick={() => isEditing ? handleCancel() : setIsEditing(true)} 
              className="golf-pools-back-link right"
            >
              {isEditing ? "Cancel" : "Edit"}
            </button>
          )}
        </div>
      </div>

      <div className="golf-pools-sub-title">
        <h2>Team: {ownerName}</h2>
      </div>

      <div className="tournament-stats">
        <div className="stat-item">
          <div className="stat-content">
            <div className="stat-title">Tournament Status</div>
            <StatusBadge status={poolData.tournament_status || 'Not Started'} />
          </div>
        </div>
        <div className="stat-item">
          <div className="stat-content">
            <div className="stat-title">Round Status</div>
            <StatusBadge status={poolData.round_status || 'Not Started'} />
          </div>
        </div>
        <div className="stat-item">
          <div className="stat-content">
            <div className="stat-title">Current Round</div>
            <div className="stat-text">{poolData.current_round || '--'}</div>
          </div>
        </div>
        <div className="stat-item">
          <div className="stat-content">
            <div className="stat-title">Cut Line</div>
            <div className="stat-text">{poolData.cut_value || '--'}</div>
          </div>
        </div>
      </div>

      <TeamLineItemsTable 
        lineItems={lineItems} 
        teamId={teamId} 
        poolData={poolData}
        onDataUpdate={handleLineItemsUpdate}
        isEditing={isEditing}
        playerFixtures={playerFixtures}
        selectedPlayers={selectedPlayers}
        onPlayerSelect={handlePlayerSelect}
      />

      {showTieBreakerRow && (
        <div className="tie-breaker-container">
          <div className="tie-breaker-row">
            <div className="tie-breaker-label">Tie Breaker Score</div>
            <div className="tie-breaker-value">
              {isEditing ? (
                <div className="tie-breaker-input-container">
                  <input
                    type="number"
                    min="0"
                    className="tie-breaker-input"
                    value={tieBreakerScore}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "" || parseInt(value) >= 0) {
                        setTieBreakerScore(value);
                      }
                    }}
                    placeholder="Enter total score"
                  />
                  <div className="tie-breaker-helper">(i.e. 276 not -12)</div>
                </div>
              ) : (
                <div className="tie-breaker-display">{tieBreakerScore || "--"}</div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Mobile View */}
      <div className="team-cards-mobile">
        {selectedPlayers.map((player, index) => {
          const currentPlayerId = player?.id?.toString() || "";
          const isPlayerEmpty = !player?.name;
          return (
            <div key={index} className="team-card-mobile">
              <div className="team-card-header">
                {isEditing ? (
                  <div className="select-container">
                    <select 
                      className="team-card-select" 
                      onChange={(e) => handlePlayerSelect(index, e)} 
                      value={currentPlayerId}
                    >
                      <option value="">Select a Player</option>
                      {playerFixtures.map((fixture) => {
                        const isSelected = currentPlayerId === fixture.playerId.toString();
                        return (
                          <option
                            key={fixture.key}
                            value={fixture.playerId}
                            data-player-id={fixture.playerId}
                            data-player-name={fixture.playerName}
                            data-world-ranking={fixture.worldRanking}
                            disabled={!isSelected && isPlayerSelected(fixture.playerId, selectedPlayers, index)}
                          >
                            {fixture.value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : (
                  <div className="team-card-value">
                    {isPlayerEmpty ? (
                      <span className="not-selected-text">
                        <MdErrorOutline className="not-selected-icon" />
                        Player Not Selected
                      </span>
                    ) : (
                      player.name
                    )}
                  </div>
                )}
              </div>
              <div className="team-card-content">
                <div className="team-card-row">
                  <span className="team-card-label">World Ranking:</span>
                  <span className="team-card-value">{player?.worldRanking || "--"}</span>
                </div>
                <div className="team-card-row">
                  <span className="team-card-label">Position:</span>
                  <span className="team-card-value">
                    {isPlayerEmpty ? "--" : (validRecords[index]?.tournament_position || "--")}
                  </span>
                </div>
                <div className="team-card-row">
                  <span className="team-card-label">To Par:</span>
                  <span className={`team-card-value team-card-score ${getScoreClass(validRecords[index]?.total_score_to_par)}`}>
                    {isPlayerEmpty ? "--" : (validRecords[index]?.total_score_to_par || "--")}
                  </span>
                </div>
              </div>
            </div>
          );
        })}

        {showTieBreakerRow && (
          <div className="tie-breaker-mobile">
            <div className="tie-breaker-mobile-header">Tie Breaker Score</div>
            {isEditing ? (
              <div>
                <input
                  type="number"
                  min="0"
                  className="tie-breaker-mobile-input"
                  value={tieBreakerScore}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === "" || parseInt(value) >= 0) {
                      setTieBreakerScore(value);
                    }
                  }}
                  placeholder="Enter total score"
                />
                <div className="tie-breaker-mobile-helper">(i.e. 276 not -12)</div>
              </div>
            ) : (
              <div className="tie-breaker-mobile-display">{tieBreakerScore || "--"}</div>
            )}
          </div>
        )}
      </div>

      {isEditing && (
        <form onSubmit={handleSave} className="golf-pools-actions">
          <button type="submit" className="golf-pools-action-button">
            Save Team <TfiSave />
          </button>
        </form>
      )}
    </div>
  );
};

const TeamViewForm = React.memo(TeamViewFormComponent);

export default TeamViewForm;
