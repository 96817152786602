import React, { useEffect, useState, useCallback } from "react";
import { PacmanLoader } from "react-spinners";
import { fetchInviteLinksByUserId } from "./PoolModalHelper";
import { supabase } from "../ReusableComponents/supabaseClient";
import PoolDetailsModal from "./PoolDetailsModal";
import StatusBadge from "../ReusableComponents/StatusBadge";
import "../styles/golfPoolStyles.css";
import moment from "moment";
import "moment-timezone";
import { useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import { FaPlus } from 'react-icons/fa';
import { logError } from "../Utilities/errorLogger";

import "./AllPools.css";


const AllPools = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPool, setSelectedPool] = useState(null);
  const [formMode, setFormMode] = useState("create");
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  const refreshData = useCallback(async () => {
    if (!userId) return;
    try {
      const fetchedData = await fetchInviteLinksByUserId(userId);
      setData(fetchedData);
    } catch (error) {
      await logError({
        filePath: 'Leaderboard/AllLeaderboards.js',
        functionName: 'fetchPools',
        errorType: 'Pools Fetch Error',
        errorMessage: error.message,
        stackTrace: error.stack,
        requestData: { userId }
      });
      console.error("Failed to fetch data:", error);

    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    const getUserId = async () => {
      const userId = localStorage.getItem("userId");

      if (userId) {
        setUserId(userId);
      } else {
        setLoading(false); // Turn off loading if no user found
      }
    };
    getUserId();
  }, []);

  useEffect(() => {
    if (userId) {
      refreshData();
      
      const inviteLinkSubscription = supabase
        .channel("inviteLinkChanges")
        .on("postgres_changes", { event: "INSERT", schema: "public", table: "InviteLink", filter: `person=eq.${userId}` }, (payload) => {
          refreshData();
        })
        .on("postgres_changes", { event: "UPDATE", schema: "public", table: "InviteLink", filter: `person=eq.${userId}` }, (payload) => {
          refreshData();
        })
        .subscribe();

      const poolSubscription = supabase
        .channel("poolChanges")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "pool",
          },
          () => {
            refreshData();
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(inviteLinkSubscription);
        poolSubscription.unsubscribe();
      };
    }
  }, [userId, refreshData]);

  const openCreateModal = () => {
    setSelectedPool(null);
    setFormMode("create");
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleRowClick = (poolId) => {
    navigate(`/pool/${poolId}`);
  };

  const renderPoolsList = () => {
    if (data.length === 0) {
      return (
        <div className="golf-pools-empty-container">
          <p>No pools found</p>
        </div>
      );
    }

    return (
      <>
        {/* Desktop view */}
        <div className="desktop-only golf-pools-table-wrapper">
          <table className="golf-pools-table">
            <thead>
              <tr>
                <th className="text-center all-pools-pool-number">Pool #</th>
                <th className="text-center all-pools-tournament">Tournament</th>
                <th className="text-center all-pools-date">Date</th>
                <th className="text-center all-pools-status">Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id} className="clickable" onClick={() => handleRowClick(item.pool?.id)}>
                  <td className="all-pools-pool-number">{item.pool?.id}</td>
                  <td className="all-pools-tournament truncate">{item.pool?.tournament_fixture?.name}</td>
                  <td className="all-pools-date">{moment(item.pool?.tournament_fixture?.start_date).format("MM/DD/YY")}</td>
                  <td className="all-pools-status">
                    <div className="status-badge-container">
                      <StatusBadge status={item.pool?.tournament_status || 'Not Started'} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Mobile view */}
        <div className="mobile-only pools-cards-container">
          {data.map((item) => (
            <div 
              key={item.id} 
              className="pool-card" 
              onClick={() => handleRowClick(item.pool?.id)}
            >
              <div className="pool-card-header">
                <div className="item-header">
                  <h3>Pool #{item.pool?.id}</h3>
                  <StatusBadge status={item.pool?.tournament_status || 'Not Started'} />
                </div>
              </div>
              <div className="pool-card-content">
                <div className="pool-card-detail">
                  <span className="detail-label">Tournament:</span>
                  <span className="detail-value">{item.pool?.tournament_fixture?.name}</span>
                </div>
                <div className="pool-card-detail">
                  <span className="detail-label">Date:</span>
                  <span className="detail-value">
                    {moment(item.pool?.tournament_fixture?.start_date).format("MM/DD/YY")}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  if (loading) {
    return (
      <div className="golf-pools-card">
        <div className="golf-pools-container">
          <div className="golf-pools-content">
            <div className="golf-pools-loading-container">
              <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="golf-pools-card">
      <div className="golf-pools-container">
        <div className="golf-pools-header">
          <div className="golf-pools-header-row">
            <button onClick={() => navigate(-1)} className="golf-pools-back-link">
              <IoChevronBackOutline /> Back
            </button>
            <h1 className="golf-pools-title">My Pools</h1>
            <button onClick={openCreateModal} className="create-pool-button" >
            <FaPlus /> Pool
            </button>
          </div>
        </div>

        {renderPoolsList()}
      </div>

      {modalIsOpen && (
        <PoolDetailsModal
          isOpen={modalIsOpen}
          onClose={closeModal}
          mode="insert"
          loggedInUser={userId}
        />
      )}
    </div>
  );
};

export default AllPools;
