import React, { useLayoutEffect, useCallback, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import useForm from "./useForm";
import useWindowSize from "../ReusableComponents/useWindowSize";
import BackgroundWrapper from "./HomeBackgroundWrapper";
import { useFetchUser } from "./useFetchUsers";
import useSupabaseUserAuth from "../Utilities/useSupabaseUserAuth";
import { useAuth } from "../Utilities/AuthContext";
import { debounce } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import PacmanLoader from "react-spinners/PacmanLoader";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";
import { logError } from '../Utilities/errorLogger';

function Login() {
  const navigate = useNavigate();
  const { fetchUser, loading } = useFetchUser();
  const windowSize = useWindowSize();
  const formRef = useRef();
  const [overlaySize, setOverlaySize] = useState({ width: 0, height: 0 });
  const { createUser, initiatePasswordReset, verifyPassword, fixPasswordHash } = useSupabaseUserAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { login } = useAuth();
  const isLoading = loading || isSubmitting;

  const initialFormState = {
    isLogin: true,
    forgotPassword: false,
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  };

  const initialFormErrors = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  };

  const { formState, formErrors, setFormErrors, handleInputChange, clearFormErrors, handleTabClick, handleForgotPassword } = useForm(initialFormState, initialFormErrors);

  useLayoutEffect(() => {
    const updateOverlaySize = () => {
      if (formRef.current) {
        const rect = formRef.current.getBoundingClientRect();
        setOverlaySize({ width: rect.width, height: rect.height });
      }
    };

    const observer = new MutationObserver(updateOverlaySize);
    if (formRef.current) {
      observer.observe(formRef.current, { attributes: true, childList: true, subtree: true });
    }

    const debouncedUpdateOverlaySize = debounce(updateOverlaySize, 300);
    window.addEventListener("resizeLogin", debouncedUpdateOverlaySize);

    updateOverlaySize();

    return () => {
      window.removeEventListener("resizeLogin", debouncedUpdateOverlaySize);
      debouncedUpdateOverlaySize.cancel();
      observer.disconnect();
    };
  }, []);

  const validateInputs = useCallback(
    (formData) => {
      const errors = {};

      if (!formData.email) {
        errors.email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        errors.email = "Please enter a valid email address";
      }

      // Only validate password for login and register
      if (!formState.forgotPassword) {
        if (!formData.password) {
          errors.password = "Password is required";
        } else if (formData.password.length < 6) {
          errors.password = "Password must be at least 6 characters long";
        }
      }

      // Only validate name fields for registration
      if (!formState.isLogin && !formState.forgotPassword) {
        if (!formData.firstName) {
          errors.firstName = "First name is required";
        }
        if (!formData.lastName) {
          errors.lastName = "Last name is required";
        }
      }

      return errors;
    },
    [formState.forgotPassword, formState.isLogin]
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const validationErrors = validateInputs(formState);

      if (Object.keys(validationErrors).length > 0) {
        setFormErrors(validationErrors);
        return;
      }

      setIsSubmitting(true);
      const email = formState.email.trim().toLowerCase();
      const password = formState.password.trim();
      const buttonName = event.nativeEvent.submitter.name;
      clearFormErrors();

      try {
        if (buttonName === "login") {
          let userData = await fetchUser(email);

          if (!userData) {
            setFormErrors({ email: "No account found with this email" });
            setIsSubmitting(false);
            return;
          }

          // Temporary fix: update password hash if it's not hashed
          if (!userData.password.startsWith("$2a$")) {
            await fixPasswordHash(email);
            // Fetch updated user data
            userData = await fetchUser(email);
          }

          // Verify password using bcrypt
          const isPasswordValid = await verifyPassword(password, userData.password);

          if (!isPasswordValid) {
            setFormErrors({ password: "Incorrect password" });
            setIsSubmitting(false);
            return;
          }

          await login(userData.id, userData.full_name, userData.email);
          navigate("/home", { replace: true });
        } else if (buttonName === "register") {
          try {
            // Create new user using Supabase
            const userData = await createUser(formState);
            
            if (!userData) {
              throw new Error("Failed to create user account");
            }

            // Log in the newly created user
            await login(userData.id, userData.full_name, userData.email);

            // Show success message
            toast.success("Account created successfully!", { theme: "dark" });

            // Navigate to home page
            navigate("/home", { replace: true });
          } catch (error) {
            if (error.message.includes("already exists")) {
              setFormErrors({ email: "An account with this email already exists" });
            } else {
              toast.error("Failed to create account. Please try again.", { theme: "dark" });
            }
            setIsSubmitting(false);
            return;
          }
        } else if (buttonName === "resetPassword") {
          // Check if user exists before sending reset email
          const userData = await fetchUser(email);
          if (!userData) {
            setFormErrors({ email: "No account found with this email" });
            setIsSubmitting(false);
            return;
          }

          // Initiate password reset
          await initiatePasswordReset(email);
          // Reset form and show login tab
          handleTabClick("login");
          toast.success("Check your email for password reset instructions", { theme: "dark" });
        }
      } catch (error) {
        console.error("Authentication error:", error);
        await logError({
          filePath: 'LoginComponents/Login.js',
          functionName: 'handleSubmit',
          errorType: 'Authentication Error',
          errorMessage: error.message,
          stackTrace: error.stack,
          requestData: { buttonName, email }
        });
        toast.error(
          error.message ||
            (buttonName === "login"
              ? "Failed to log in. Please try again."
              : buttonName === "register"
              ? "Failed to create account. Please try again."
              : "Failed to initiate password reset. Please try again."),
          { theme: "dark" }
        );
      } finally {
        setIsSubmitting(false);
      }
    },
    [formState, clearFormErrors, setFormErrors, fetchUser, login, navigate, createUser, initiatePasswordReset, verifyPassword, handleTabClick, fixPasswordHash, validateInputs]
  );

  const handleContactClick = useCallback(() => {
    // Directly call the modal function from the parent window
    if (typeof window !== "undefined" && window.openContactModal) {
      window.openContactModal();
    }
  }, []);

  // Check for refresh flag on component mount
  React.useEffect(() => {
    const shouldRefresh = localStorage.getItem("shouldRefresh");
    if (shouldRefresh) {
      localStorage.removeItem("shouldRefresh");
      window.location.reload();
    }
  }, []);

  return (
    <div className="main-container">
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      <BackgroundWrapper windowSize={windowSize}>
        <div className="auth-container">
          <div className="auth-form-container" ref={formRef}>
            {isLoading && (
              <div
                className="loading-overlay"
                style={{
                  width: overlaySize.width,
                  height: overlaySize.height,
                }}
              >
                <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
              </div>
            )}
            <LoginForm
              formState={formState}
              formErrors={formErrors}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              handleTabClick={handleTabClick}
              handleForgotPassword={handleForgotPassword}
            />
          </div>
        </div>
      </BackgroundWrapper>
      <div className="help-bar">
        <div className="help-bar-content">
          <span>Questions or need help getting started?</span>
          <span className="help-bar-link" onClick={handleContactClick}>
            Contact Us
          </span>
        </div>
      </div>
    </div>
  );
}

export default Login;