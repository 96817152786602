import { useState, useEffect } from "react";
import { supabase } from "../ReusableComponents/supabaseClient";
import { toast } from "react-toastify";
import { logError } from '../Utilities/errorLogger';

export const selectedPoolDataStructure = {
  id: null,
  tournamentStatus: "",
  cutValue: "",
  currentRound: "",
};

export const fetchPoolDetailsById = async (poolId) => {
  try {
    const { data, error } = await supabase
      .from("pool")
      .select(`
        id, 
        name, 
        tournament_status, 
        round_status, 
        cut_value, 
        time_zone,
        first_tee_time,
        current_round,
        tournament_fixture (
          id,
          name,
          start_date
        )
      `)
      .eq("id", poolId)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    await logError({
      filePath: 'Leaderboard/LeaderboardHelper.js',
      functionName: 'fetchPoolDetailsById',
      errorType: 'Fetch Pool Details Error',
      errorMessage: error.message,
      stackTrace: error.stack
    });
    toast.error(`Error fetching pool details: ${error.message}`, { theme: "dark" });
    throw error;
  }
};

export const useFetchPoolLeaderboardOptions = (selectedPoolId) => {
  const [poolOptions, setPoolOptions] = useState([]);
  const [poolLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!Number(localStorage.getItem("userId"))) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      const { data, error } = await supabase
        .from("team")
        .select("pool(id, name, current_round, cut_value, tournament_status, round_status, tournament_fixture(id, start_date)), owner(id)")
        .eq("owner", Number(localStorage.getItem("userId")));

      if (error) {
        toast.error(`Error: ${error}`, { theme: "dark" });
        setLoading(false);
        return;
      }

      if (data) {
        data.sort((a, b) => {
          const dateA = new Date(a.pool.tournament_fixture.start_date),
            dateB = new Date(b.pool.tournament_fixture.start_date);
          if (dateA - dateB === 0) {
            return a.pool.id - b.pool.id;
          }
          return dateA - dateB;
        });
        setPoolOptions(data);
      }
      setLoading(false);
    };

    fetchData();

    const poolTableSubscription = supabase
      .channel("poolTableSubscription")
      .on("postgres_changes", { event: "UPDATE", schema: "public", table: "pool", filter: `id=eq.${selectedPoolId}` }, (payload) => {
        fetchData();
      })
      .subscribe();

    return () => supabase.removeChannel(poolTableSubscription);
  }, [selectedPoolId]);

  return { poolOptions, poolLoading };
};

export const useLeaderboardData = (poolId) => {
  const [teams, setTeams] = useState([]);
  const [teamLineItems, setTeamLineItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!poolId) {
      setTeams([]);
      setTeamLineItems([]);
      return;
    }

    setLoading(true);

    const fetchData = async () => {
      try {
        // First fetch pool data to get max_players
        const { data: poolData, error: poolError } = await supabase.from("pool").select("max_players").eq("id", poolId).single();

        if (poolError) {
          toast.error(`Error fetching pool data: ${poolError.message}`, { theme: "dark" });
          throw poolError;
        }

        // Then fetch teams
        const { data: teamsData, error: teamsError } = await supabase
          .from("team")
          .select("id, paid, missed_cut, number_of_line_items, rank, team_world_ranking_average, team_score_to_par, team_score_total, owner(id, full_name), pool(id,tournament_status,current_round, tournament_fixture(start_date))")
          .eq("pool", poolId)
          .eq("paid", true)
          .eq("number_of_line_items", poolData.max_players)
          .order("rank", { ascending: true });


        if (teamsError) {
          toast.error(`Error fetching teams: ${teamsError.message}`, { theme: "dark" });
          throw teamsError;
        }

        if (teamsData) {
          teamsData.sort(sortTeams);
          console.log("teamsData", teamsData);
          setTeams(teamsData);

          // Only fetch line items if we have teams
          if (teamsData.length > 0) {
            const teamIds = teamsData.map((team) => team.id);
            const { data: lineItemsData, error: lineItemsError } = await supabase
              .from("teamLineItem")
              .select(
                "id, team, player_world_ranking, tee_time, holes_played, players_tournament_status, total_score, total_score_to_par, round_1_to_par, round_2_to_par, round_3_to_par, round_4_to_par, player(id, player_name), pool(num_player_used_for_scoring, tournament_status, current_round)"
              )
              .in("team", teamIds);

            if (lineItemsError) {
              toast.error(`Error fetching line items: ${lineItemsError.message}`, { theme: "dark" });
              throw lineItemsError;
            }

            if (lineItemsData) {
              lineItemsData.sort(sortTeamLineItems);
              setTeamLineItems(lineItemsData);
            }
          }
        }
      } catch (error) {
        await logError({
          filePath: 'Leaderboard/LeaderboardHelper.js',
          functionName: 'useLeaderboardData',
          errorType: 'Leaderboard Data Fetch Error',
          errorMessage: error.message,
          stackTrace: error.stack
        });
        toast.error(`Error: ${error.message}`, { theme: "dark" });
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Set up subscriptions for both tables
    const teamTableSubscription = supabase
      .channel("teamTableSubscription")
      .on("postgres_changes", { event: "*", schema: "public", table: "team", filter: `pool=eq.${poolId}` }, () => {
        fetchData();
      })
      .subscribe();

    const teamLineItemTableSubscription = supabase
      .channel("teamLineItemTableSubscription")
      .on("postgres_changes", { event: "*", schema: "public", table: "teamLineItem", filter: `pool=eq.${poolId}` }, () => {
        fetchData();
      })
      .subscribe();

    return () => {
      supabase.removeChannel(teamTableSubscription);
      supabase.removeChannel(teamLineItemTableSubscription);
    };
  }, [poolId]);

  return { teams, teamLineItems, loading };
};

const sortTeams = (a, b) => {
  // Special handling for "MC" rank and "--" scoreTotal, including "E" as 0
  const isMCA = a.rank.toUpperCase() === "MC";
  const isMCB = b.rank.toUpperCase() === "MC";

  const scoreA = a.team_score_to_par === "--" ? Infinity : a.team_score_to_par.toUpperCase() === "E" ? 0 : parseInt(a.team_score_to_par, 10) || Infinity;

  const scoreB = b.team_score_to_par === "--" ? Infinity : b.team_score_to_par.toUpperCase() === "E" ? 0 : parseInt(b.team_score_to_par, 10) || Infinity;

  // Sort teams with scoreTotal of "--" to the bottom
  if (scoreA === Infinity && scoreB !== Infinity) return 1;
  if (scoreA !== Infinity && scoreB === Infinity) return -1;

  // Next, handle the "MC" ranks
  if (isMCA && !isMCB) return 1;
  if (!isMCA && isMCB) return -1;

  // If both have "MC" ranks, then sort by score
  if (isMCA && isMCB) {
    return scoreA - scoreB;
  }

  // Function to extract numeric rank, treating "T" prefixes as ties (e.g., "T1" becomes 1)
  const getNumericRank = (rank) => parseInt(rank.replace("T", ""), 10);
  const rankA = getNumericRank(a.rank);
  const rankB = getNumericRank(b.rank);

  // First sort by rank
  const rankDifference = rankA - rankB;
  if (rankDifference !== 0) {
    return rankDifference;
  }

  // Then sort by score if ranks are equal
  const scoreDifference = scoreA - scoreB;
  if (scoreDifference !== 0) {
    return scoreDifference;
  }

  // Finally, if all scores are 0, prioritize user's team
  if (a.team_score_total === "0" && b.team_score_total === "0") {
    const currentUserId = Number(localStorage.getItem("userId"));
    const isUserTeamA = a.owner.id === currentUserId;
    const isUserTeamB = b.owner.id === currentUserId;
    
    if (isUserTeamA) return -1;
    if (isUserTeamB) return 1;
  }

  return 0; // If everything is equal
};

const sortTeamLineItems = (a, b) => {
  const scoreToNumeric = (score) => {
    if (score === "E" || score === "-" || score === "--") return 0;
    return parseInt(score, 10);
  };

  const totalScoreToNumeric = (score) => {
    if (score === "0" || score === "-" || score === "--") return Number.MAX_SAFE_INTEGER; // Push "0", "-", "--" to the bottom
    return parseInt(score, 10);
  };

  const isInactiveStatus = (status) => {
    return ["cut", "wd", "dq"].includes(status.toLowerCase());
  };

  const aScore = scoreToNumeric(a.total_score_to_par);
  const bScore = scoreToNumeric(b.total_score_to_par);

  const aTotalScore = totalScoreToNumeric(a.total_score);
  const bTotalScore = totalScoreToNumeric(b.total_score);

  const aInactive = isInactiveStatus(a.players_tournament_status);
  const bInactive = isInactiveStatus(b.players_tournament_status);

  // Check if both players are inactive or active, then sort based on total score to par
  if (aInactive === bInactive) {
    if (aScore !== bScore) {
      return aScore - bScore; // Sort by score to par value
    }

    // If score to par values are equal, sort by total score
    return aTotalScore - bTotalScore;
  }

  // If only one player is inactive, sort to put inactive players lower
  return aInactive ? 1 : -1;
};
