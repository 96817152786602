import { useState, useEffect, useCallback } from "react";
import { supabase } from "../ReusableComponents/supabaseClient";
import { toast } from "react-toastify";
import moment from "moment";
import "moment-timezone";
import { logError } from "../Utilities/errorLogger";

export const fetchPoolsForUser = async (userId) => {
  const formattedDate = getTournamentTimeReference();

  try {
    const { data, error } = await supabase
      .from("team")
      .select(
        `
      pool(
        id,
        name,
        tournament_fixture:pool_tournament_fixture_fkey(end_date)
      )
    `
      )
      .eq("owner", userId)
      .order("pool", { ascending: true });

    if (error) {
      toast.error(`Error fetching pools: ${error.message}`, { theme: "dark" });
      throw error;
    }

    const filteredData = data.filter((item) => {
      const poolEndDate = new Date(item.pool.tournament_fixture.end_date);
      return poolEndDate >= new Date(formattedDate);
    });

    const options = filteredData.map((item) => ({
      value: item.pool.id,
      label: `Pool #${item.pool.id}`,
    }));

    return options;
  } catch (error) {
    await logError({
      filePath: "Team/TeamHelper.js",
      functionName: "fetchPoolsForUser",
      errorType: "Error",
      errorMessage: error.message,
      stackTrace: error.stack,
    });
    toast.error(`Error fetching pools: ${error.message}`, { theme: "dark" });
    throw error;
  }
};

export const useFetchPoolAndLineItemData = (poolNumber, refetchTrigger, teamId = null) => {
  const [poolData, setPoolData] = useState(null);
  const [teamLineItem, setTeamLineItem] = useState(null);
  const [hasLineItems, setHasLineItems] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [teamStatus, setTeamStatus] = useState(null);
  const currentUserId = Number(localStorage.getItem("userId"));

  const handleError = useCallback((err) => {
    setError(err.message);
    setPoolData(null);
    setTeamLineItem(null);
    setHasLineItems(false);
    setIsOwner(false);
    setTeamStatus(null);
  }, []);

  const convertScore = (score) => {
    if (score === "E") return 0;
    if (score.includes("+")) return parseInt(score.replace("+", ""), 10);
    if (score.includes("-")) return parseInt(score, 10);
    return parseInt(score, 10);
  };

  const sortLineItems = useCallback((data) => {
    return data.sort((a, b) => {
      const scoreA = convertScore(a.total_score_to_par);
      const scoreB = convertScore(b.total_score_to_par);
      if (scoreA !== scoreB) return scoreA - scoreB;
      return a.player_world_ranking - b.player_world_ranking;
    });
  }, []);

  const fetchData = useCallback(async () => {
    if (!poolNumber) {
      setError("No pool number provided");
      setLoading(false);
      return;
    }

    setError(false);
    setLoading(true);
    setPoolData(null);
    setTeamLineItem(null);
    setHasLineItems(false);
    setIsOwner(false);
    setTeamStatus(null);

    try {
      // First get the team and its related pool data
      const { data: teamData, error: teamError } = await supabase
        .from("team")
        .select(
          `
          id,
          tie_breaker_score,
          owner,
          user!owner (
            id,
            full_name,
            can_create_late_team
          ),
          pool!inner (
            id,
            max_players,
            tie_breaker,
            name,
            round_status,
            current_round,
            cut_value,
            first_tee_time,
            time_zone,
            num_player_used_for_scoring,
            tournament_status,
            tournament_fixture (
              id,
              name,
              tournament_id,
              start_date
            )
          )
        `
        )
        .eq("pool", poolNumber)
        .eq(teamId ? "id" : "owner", teamId || currentUserId)
        .order("id", { ascending: false })
        .single();

      if (teamError && teamError.code !== "PGRST116") {
        // Ignore "not found" error
        throw teamError;
      }

      if (teamData) {
        setIsOwner(teamData.owner === currentUserId);
        setTeamStatus(teamData.active);
        setPoolData(teamData.pool);

        // Get team line items in a separate query
        const { data: lineItems, error: lineItemError } = await supabase
          .from("teamLineItem")
          .select(
            `
            id,
            display_order,
            player_world_ranking,
            total_score_to_par,
            tournament_position,
            playersFixture (
              id,
              player_name,
              world_ranking_position
            )
          `
          )
          .eq("team", teamData.id)
          .order("display_order", { ascending: true });

        if (lineItemError) {
          throw lineItemError;
        }

        // Add team data to each line item or create a single record with team data
        if (lineItems?.length > 0) {
          const itemsWithTeam = lineItems.map((item) => ({
            ...item,
            player: item.playersFixture,
            team: {
              id: teamData.id,
              tie_breaker_score: teamData.tie_breaker_score,
              owner: teamData.user,
            },
          }));
          const sortedLineItems = sortLineItems(itemsWithTeam);
          setTeamLineItem(sortedLineItems);
          setHasLineItems(true);
        } else {
          // Create a single record with team data for new teams
          setTeamLineItem([
            {
              team: {
                id: teamData.id,
                tie_breaker_score: teamData.tie_breaker_score,
                owner: teamData.user,
              },
            },
          ]);
          setHasLineItems(false);
        }
      } else {
        // If no team found, just get the pool data
        const { data: poolData, error: poolError } = await supabase
          .from("pool")
          .select(
            `
            id,
            max_players,
            tie_breaker,
            name,
            round_status,
            current_round,
            cut_value,
            num_player_used_for_scoring,
            first_tee_time,
            time_zone,
            tournament_status,
            tournament_fixture (
              id,
              name,
              tournament_id,
              start_date
            )
          `
          )
          .eq("id", poolNumber)
          .single();

        if (poolError) {
          throw poolError;
        }

        setPoolData(poolData);
        setTeamLineItem([]);
        setHasLineItems(false);
      }
    } catch (err) {
      await logError({
        filePath: "Team/TeamHelper.js",
        functionName: "fetchData",
        errorType: "Error",
        errorMessage: err.message,
        stackTrace: err.stack,
      });
      handleError(err);
    } finally {
      setLoading(false);
    }
  }, [poolNumber, teamId, currentUserId, handleError, sortLineItems]);

  useEffect(() => {
    fetchData();
  }, [fetchData, refetchTrigger]);

  return {
    poolData,
    teamLineItem,
    hasLineItems,
    loading,
    error,
    isOwner,
    teamStatus,
  };
};

export const useInsertTeamRecord = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const insertTeam = async (poolId) => {
    setLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("team")
        .insert([
          {
            owner: Number(localStorage.getItem("userId")),
            pool: poolId,
          },
        ])
        .select();

      if (error) throw error;
      return data[0];
    } catch (error) {
      await logError({
        filePath: "Team/TeamHelper.js",
        functionName: "insertTeam",
        errorType: "Error",
        errorMessage: error.message,
        stackTrace: error.stack,
      });
      toast.error(`Error inserting team: ${error.message}`, { theme: "dark" });
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { insertTeam, loading, error };
};

export const useInsertTeamLineItems = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const insertLineItems = async (lineItems) => {
    setLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase.from("teamLineItem").insert(lineItems).select();

      if (error) throw error;
      return data;
    } catch (error) {
      await logError({
        filePath: "Team/TeamHelper.js",
        functionName: "insertLineItems",
        errorType: "Error",
        errorMessage: error.message,
        stackTrace: error.stack,
      });
      toast.error(`Error inserting line items: ${error.message}`, { theme: "dark" });
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { insertLineItems, loading, error };
};

export const fetchTeamId = async (poolId) => {
  try {
    const { data, error } = await supabase
      .from("team")
      .select("id")
      .eq("pool", poolId)
      .eq("owner", Number(localStorage.getItem("userId")))
      .single();

    if (error) throw error;
    return data?.id;
  } catch (error) {
    await logError({
      filePath: "Team/TeamHelper.js",
      functionName: "fetchTeamId",
      errorType: "Error",
      errorMessage: error.message,
      stackTrace: error.stack,
    });
    toast.error(`Error fetching team ID: ${error.message}`, { theme: "dark" });
    return null;
  }
};

export const useUpdateTeamLineItem = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateLineItem = async (id, updates) => {
    setLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase.from("teamLineItem").update(updates).eq("id", id).select();

      if (error) throw error;
      return data;
    } catch (error) {
      await logError({
        filePath: "Team/TeamHelper.js",
        functionName: "updateLineItem",
        errorType: "Error",
        errorMessage: error.message,
        stackTrace: error.stack,
      });
      toast.error(`Error updating line item: ${error.message}`, { theme: "dark" });
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { updateLineItem, loading, error };
};

export const useUpdateTeamRecord = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateTeam = async (id, updates) => {
    setLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase.from("team").update(updates).eq("id", id).select();

      if (error) throw error;
      return data;
    } catch (error) {
      await logError({
        filePath: "Team/TeamHelper.js",
        functionName: "updateTeam",
        errorType: "Error",
        errorMessage: error.message,
        stackTrace: error.stack,
      });
      toast.error(`Error updating team: ${error.message}`, { theme: "dark" });
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { updateTeam, loading, error };
};

export const setTeam = (poolData, tieBreakerScore) => {
  return {
    owner: Number(localStorage.getItem("userId")),
    pool: poolData.id,
    tie_breaker_score: tieBreakerScore,
  };
};

export const setTeamLineItems = (selectedPlayers, poolId, teamId) => {
  return selectedPlayers.map((player) => ({
    team: teamId,
    pool: poolId,
    player: player.value,
    player_world_ranking: player.world_ranking,
  }));
};

export const formatDate = (dateString) => {
  return moment(dateString).format("MM/DD/YY");
};

const transformData = (data) =>
  data.map((item) => ({
    value: item.player.id,
    label: item.player.player_name,
    world_ranking: item.player.world_ranking_position,
  }));

function getCurrentCentralTime() {
  return moment().tz("America/Chicago");
}

export const isOptionDisabled = (optionPlayerFixture, selectedPlayers, currentDropdownIndex) => {
  if (!selectedPlayers || typeof selectedPlayers !== "object") return false;
  return Object.entries(selectedPlayers).some(([index, player]) => {
    const indexNum = parseInt(index);
    return player?.playerId === optionPlayerFixture.value && indexNum !== currentDropdownIndex;
  });
};

export const PlayerSelectDisabled = (optionPlayerFixture, selectedPlayers, currentDropdownIndex) => {
  if (!selectedPlayers || typeof selectedPlayers !== "object") return false;
  return Object.entries(selectedPlayers).some(([index, player]) => {
    const indexNum = parseInt(index);
    return player?.playerId === optionPlayerFixture.value && indexNum !== currentDropdownIndex;
  });
};

export const editOptionDisabled = (optionPlayerId, selections, currentDropdownIndex) => {
  if (!selections || typeof selections !== "object") return false;
  return Object.entries(selections).some(([index, selection]) => {
    const indexNum = parseInt(index);
    return selection?.playerId === optionPlayerId && indexNum !== currentDropdownIndex;
  });
};

export const validateSelections = (selectedPlayers, poolData, tieBreakerScore) => {
  if (!poolData) {
    return "Pool data is required";
  }

  if (selectedPlayers.length !== poolData.max_players) {
    return `Please select exactly ${poolData.max_players} players`;
  }

  if (selectedPlayers.some((player) => !player)) {
    return "All player selections are required";
  }

  if (poolData.tie_breaker && !tieBreakerScore) {
    return "Tie breaker score is required";
  }

  return null;
};

function getTournamentTimeReference() {
  const currentTime = getCurrentCentralTime();
  return currentTime.format();
}

export const canEditRecord = (pool) => {
  const timeZone = pool.time_zone;
  if (!timeZone) {
    toast.error("No timezone specified in pool data", { theme: "dark" });
    return false;
  }

  const now = moment().tz(timeZone);
  const firstTeeTime = pool.first_tee_time ? moment.tz(pool.first_tee_time, timeZone) : null;

  if (!firstTeeTime) {
    return true;
  }

  return now.isBefore(firstTeeTime);
};
