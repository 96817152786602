import React from 'react';
import { TbGolf } from "react-icons/tb";
import { IoGolfOutline } from "react-icons/io5";
import { FaFlagCheckered } from "react-icons/fa";
import { MdOutlinePendingActions } from "react-icons/md";
import { BiErrorCircle } from "react-icons/bi";
import './StatusBadge.css';

const StatusBadge = ({ status }) => {
  const getBadgeContent = () => {
    switch (status) {
      case 'Not Started':
        return {
          icon: <TbGolf className="status-badge-icon" />,
          text: 'Not Started',
          className: 'not-started'
        };
      case 'In Progress':
        return {
          icon: <IoGolfOutline className="status-badge-icon" />,
          text: 'In Progress',
          className: 'in-progress'
        };
      case 'Completed':
        return {
          icon: <FaFlagCheckered className="status-badge-icon" />,
          text: 'Completed',
          className: 'completed'
        };
      case 'Official':
        return {
          icon: <MdOutlinePendingActions className="status-badge-icon" />,
          text: 'Official',
          className: 'official'
        };
      case 'Suspended':
        return {
          icon: <BiErrorCircle className="status-badge-icon" />,
          text: 'Suspended',
          className: 'suspended'
        };
      default:
        return {
          icon: <TbGolf className="status-badge-icon" />,
          text: status,
          className: 'default'
        };
    }
  };

  const { icon, text, className } = getBadgeContent();

  return (
    <div className={`status-badge ${className}`}>
      {icon}
      <span>{text}</span>
    </div>
  );
};

export default StatusBadge; 