import moment from "moment-timezone";
import { supabase } from "../ReusableComponents/supabaseClient";
import { toast } from "react-toastify";

export const getScoreClass = (score) => {
  if (score === "E" || score === "--") return "score-even";
  const numScore = parseInt(score);
  if (isNaN(numScore)) return "score-even";
  return numScore < 0 ? "golf-pools-score-over" : "score-over";
};

export const isPlayerSelected = (playerId, selectedPlayers, currentIndex) => {
  if (!playerId || !selectedPlayers || !Array.isArray(selectedPlayers)) return false;
  return selectedPlayers.some((player, index) => 
    index !== currentIndex && player?.id === playerId
  );
};

export const validateTeamSelection = (players) => {
  const emptySlots = players
    .map((player, index) => (!player?.id ? index + 1 : null))
    .filter((slot) => slot !== null);

  if (emptySlots.length > 0) {
    const slotsText = emptySlots.length === 1 
      ? `Player ${emptySlots[0]}` 
      : `Players ${emptySlots.join(", ")}`;
    toast.error(`Please select ${slotsText} to complete your team`, { theme: "dark" });
    return false;
  }
  return true;
};

export const validateTieBreaker = (showTieBreaker, score) => {
  if (!showTieBreaker) return true;

  if (!score || score.trim() === "") {
    toast.error("Please enter a tie breaker score", { theme: "dark" });
    return false;
  }

  const parsedScore = parseInt(score);
  if (isNaN(parsedScore)) {
    toast.error("Tie breaker score must be a valid number", { theme: "dark" });
    return false;
  }

  if (parsedScore < 0) {
    toast.error("Tie breaker score must be a positive number", { theme: "dark" });
    return false;
  }

  return true;
};

export const getChangedLineItems = (newPlayers, records) => {
  if (!Array.isArray(newPlayers) || !Array.isArray(records)) {
    toast.error("Invalid data format", { theme: "dark" });
    return [];
  }

  // Create a map of existing records by display_order for quick lookup
  const existingRecordsByOrder = records.reduce((acc, record) => {
    if (record.display_order) {
      acc[record.display_order] = record;
    }
    return acc;
  }, {});

  // Track which display_orders we've already processed
  const processedOrders = new Set();
  const changes = [];

  newPlayers.forEach((player, index) => {
    if (!player?.id) return;

    const displayOrder = index + 1;
    
    // Skip if we've already processed this display_order
    if (processedOrders.has(displayOrder)) return;

    const existingRecord = existingRecordsByOrder[displayOrder];
    const shouldUpdate = !existingRecord?.player?.id || 
                        (existingRecord.player?.id && existingRecord.player.id !== player.id);

    if (shouldUpdate) {
      changes.push({
        player: {
          id: player.id,
          worldRanking: player.world_ranking_position || player.worldRanking
        },
        lineItemId: existingRecord?.id,
        displayOrder
      });
      processedOrders.add(displayOrder);
    }
  });

  // Sort changes by display_order to maintain order
  return changes.sort((a, b) => a.displayOrder - b.displayOrder);
};

export const updateTieBreakerScore = async (teamId, score) => {
  if (!teamId) {
    toast.error("Team ID is required to update tie breaker score", { theme: "dark" });
    throw new Error("Team ID is required to update tie breaker score");
  }

  const { data, error } = await supabase
    .from("team")
    .update({ 
      tie_breaker_score: score,
      modified_date: moment().tz("America/Chicago").utc().format()
    })
    .eq("id", teamId)
    .select();

  if (error) {
    toast.error(`Error updating tie breaker score: ${error.message}`, { theme: "dark" });
    throw new Error(`Error updating team: ${error.message}`);
  }

  return data[0];
};

export const updateLineItem = async (teamId, poolId, userId, change) => {
  const currentTime = moment().tz("America/Chicago").utc().format();

  // If no lineItemId, check if we already have a line item at this position
  if (!change.lineItemId) {
    // First check if we already have a line item at this display_order
    const { data: existingItem } = await supabase
      .from("teamLineItem")
      .select('id')
      .eq('team', teamId)
      .eq('display_order', change.displayOrder)
      .single();

    if (existingItem) {
      // If we have an existing item at this position, update it instead
      const { data, error } = await supabase
        .from("teamLineItem")
        .update({
          player: change.player.id,
          player_world_ranking: change.player.worldRanking,
          modified_date: currentTime,
          tee_time: null
        })
        .eq("id", existingItem.id)
        .eq("team", teamId)
        .select();

      if (error) {
        toast.error(`Error updating line item: ${error.message}`, { theme: "dark" });
        throw new Error(`Error updating line item: ${error.message}`);
      }

      return data[0];
    }

    // If no existing item at this position, create a new one
    const { data, error } = await supabase
      .from("teamLineItem")
      .insert([{
        team: teamId,
        pool: poolId,
        owner: userId,
        player: change.player.id,
        player_world_ranking: change.player.worldRanking,
        created_date: currentTime,
        modified_date: currentTime,
        display_order: change.displayOrder,
        tee_time: null
      }])
      .select();

    if (error) {
      toast.error(`Error creating line item: ${error.message}`, { theme: "dark" });
      throw new Error(`Error creating line item: ${error.message}`);
    }

    return data[0];
  }

  // Update existing record (without changing display_order)
  const { data, error } = await supabase
    .from("teamLineItem")
    .update({
      player: change.player.id,
      player_world_ranking: change.player.worldRanking,
      modified_date: currentTime,
      tee_time: null
    })
    .eq("id", change.lineItemId)
    .eq("team", teamId)
    .select();

  if (error) {
    toast.error(`Error updating line item: ${error.message}`, { theme: "dark" });
    throw new Error(`Error updating line item: ${error.message}`);
  }

  return data[0];
};
