import React from "react";
import "./ModalNavBar.css";
import { IoSaveOutline } from "react-icons/io5";
import { IoMdAdd, IoMdPersonAdd } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdOutlineEmail } from "react-icons/md";
import { TfiSave } from "react-icons/tfi";
import { FaSave, FaUserPlus, FaPaperPlane } from 'react-icons/fa';

const PoolModalNavBar = ({ 
  activeSection, 
  isPoolOwner, 
  poolRecord, 
  handleSave, 
  mode,
  canEdit,
  showSaveButton,
  showInviteButtons,
  onAddEmail,
  onSendInvites,
  isSubmitting
}) => {
  const handleSendInvites = async (e) => {
    e.preventDefault();
    if (onSendInvites) {
      await onSendInvites();
    }
  };

  return (
    <div className="pool-modal-nav-bar">
      <div className="nav-bar-buttons">
        {showSaveButton && (
          <div className="center-button">
            <button
              type="button"
              name="save"
              className="modal-nav-button primary"
              onClick={handleSave}
              disabled={!canEdit || isSubmitting}
            >
              <FaSave />
              Save Changes
            </button>
          </div>
        )}
        {showInviteButtons && (
          <div className="invite-buttons-container">
            <button
              type="button"
              name="addEmail"
              className="add-email-button"
              onClick={onAddEmail}
              disabled={isSubmitting}
            >
              <FaUserPlus />
              Add Email
            </button>
            <button 
              type="button"
              className={`submit-invite-button ${isSubmitting ? 'submitting' : ''}`}
              disabled={isSubmitting}
              onClick={handleSendInvites}
            >
              <FaPaperPlane />
              Send Invites
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PoolModalNavBar; 