import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../Utilities/AuthContext";
import "./Navbar.css";
import Modal from "./Modal";
import ContactUsForm from "./ContactUsForm";
import { FaRegCaretSquareDown, FaTrophy, FaUsers, FaUserCircle, FaDonate } from "react-icons/fa";
import { FcHome } from "react-icons/fc";
import { IoGolfOutline } from "react-icons/io5";
import { logError } from "../Utilities/errorLogger";

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout, isInitialized } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const dropdownRef = useRef(null);
  const userName = localStorage.getItem("userName");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle screen size changes
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    // Check initially
    checkMobile();

    // Add resize listener
    window.addEventListener("resize", checkMobile);

    // Cleanup
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobile && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile]);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      await logError({
        filePath: "ReusableComponents/Navbar.js",
        functionName: "handleLogout",
        errorType: "Logout Error",
        errorMessage: error.message,
        stackTrace: error.stack,
      });
      console.error("Error logging out:", error);
    }
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleSubmissionStatusChange = (status) => {
    setIsSubmitting(status);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleDonateClick = () => {
    window.open("https://www.paypal.com/donate/?hosted_button_id=QVSPJU8PVXJZY", "_blank");
  };

  const handleDropdownItemClick = (action) => {
    if (typeof action === "function") {
      action();
    }
    if (isMobile) {
      setIsDropdownOpen(false);
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Render a loading state while auth initializes
  if (!isInitialized) {
    return <div className="navbar"></div>;
  }

  return (
    <div className="navbar">
      <div className="nav-links">
        <Link to="/home" className={isActive("/home") ? "active" : ""} title="Dashboard">
          <FcHome /> <span className="nav-text">Dashboard</span>
        </Link>
        <Link to="/pools" className={isActive("/pools") ? "active" : ""} title="My Pools">
          <FaUsers /> <span className="nav-text">Pools</span>
        </Link>
        <Link to="/teams" className={isActive("/teams") ? "active" : ""} title="My Teams">
          <IoGolfOutline /> <span className="nav-text">Teams</span>
        </Link>
        <Link to="/leaderboard" className={isActive("/leaderboard") ? "active" : ""} title="Leaderboards">
          <FaTrophy /> <span className="nav-text">Leaderboards</span>
        </Link>
      </div>
      <div className="nav-right">
        {/* Desktop dropdown */}
        {!isMobile && (
          <div className="user-dropdown desktop-only" ref={dropdownRef}>
            <button className="user-button">
              {userName} <FaRegCaretSquareDown />
            </button>
            <div className="dropdown-content">
              <div tabIndex="0" role="button" className="dropdown-item" onClick={() => handleDropdownItemClick(() => navigate("/faq"))}>
                FAQs
              </div>
              <div tabIndex="0" role="button" className="dropdown-item" onClick={() => handleDropdownItemClick(openModal)}>
                Contact Us
              </div>
              <div tabIndex="0" role="button" className="dropdown-item" onClick={() => handleDropdownItemClick(handleDonateClick)}>
                <FaDonate /> Donate
              </div>
              <div onClick={() => handleDropdownItemClick(handleLogout)} className="dropdown-item logout-link">
                Log Out
              </div>
            </div>
          </div>
        )}
        {/* Mobile account icon and menu */}
        {isMobile && (
          <div className="mobile-account">
            <button className="mobile-account-button" onClick={toggleMobileMenu}>
              <FaUserCircle />
            </button>
            {isMobileMenuOpen && (
              <div className="mobile-menu">
                <div
                  className="mobile-menu-item"
                  onClick={() => {
                    navigate("/faq");
                    setIsMobileMenuOpen(false);
                  }}
                >
                  FAQs
                </div>
                <div
                  className="mobile-menu-item"
                  onClick={() => {
                    openModal();
                    setIsMobileMenuOpen(false);
                  }}
                >
                  Contact Us
                </div>
                <div
                  className="mobile-menu-item"
                  onClick={() => {
                    handleDonateClick();
                    setIsMobileMenuOpen(false);
                  }}
                >
                  <FaDonate /> Donate
                </div>
                <div
                  className="mobile-menu-item logout-link"
                  onClick={() => {
                    handleLogout();
                    setIsMobileMenuOpen(false);
                  }}
                >
                  Log Out
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} isSubmitting={isSubmitting} contentType="modal">
        <ContactUsForm closeModal={closeModal} onSubmissionStatusChange={handleSubmissionStatusChange} />
      </Modal>
    </div>
  );
}

export default Navbar;
