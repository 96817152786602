import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { IoTrophyOutline, IoGolfOutline, IoSettingsOutline, IoChevronBackOutline } from "react-icons/io5";
import { MdOutlineAttachMoney, MdMoneyOff } from "react-icons/md";
import { supabase } from "../ReusableComponents/supabaseClient";
import PoolDetailsModal from "./PoolDetailsModal";
import Modal from "../ReusableComponents/Modal";
import StatusBadge from "../ReusableComponents/StatusBadge";
import InviteToPool from "./InviteToPool";
import ManagePoolEntries from "./ManagePoolEntries";
import { canEditRecord } from "./PoolModalHelper";
import { PacmanLoader } from "react-spinners";
import { FaTrophy } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/golfPoolStyles.css";
import moment from "moment-timezone";
import { logError } from "../Utilities/errorLogger";

const PoolPage = () => {
  const [pool, setPool] = useState(null);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { poolId } = useParams();
  const navigate = useNavigate();
  const loggedInUser = Number(localStorage.getItem("userId"));

  const fetchPoolData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const { data: poolData, error: poolError } = await supabase
        .from("pool")
        .select(
          `
          id,
          name,
          owner,
          tournament_status,
          number_of_teams,
          max_players,
          num_player_used_for_scoring,
          all_players_must_make_cut,
          tie_breaker,
          current_round,
          first_tee_time,
          tournament_fixture (
            tournament_id,
            season,
            start_date,
            name,
            id
          ),
          time_zone
        `
        )
        .eq("id", poolId)
        .single();

      if (poolError) throw poolError;
      if (!poolData) throw new Error("Pool not found");

      setPool(poolData);

      const { data: teamsData, error: teamsError } = await supabase
        .from("team")
        .select(
          `
          *,
          owner_details:user!inner(
            full_name,
            id
          ),
          line_items:teamLineItem (count)
        `
        )
        .eq("pool", poolId)
        .order("rank", { ascending: true, nullsLast: true });

      if (teamsError) throw teamsError;

      const { data: inviteLinks, error: inviteLinksError } = await supabase.from("InviteLink").select("*").eq("pool", poolId);

      if (inviteLinksError) throw inviteLinksError;

      const teamsWithStatus =
        teamsData?.map((team) => {
          const teamInviteLink = inviteLinks?.find((link) => link.person === team.owner_details.id);
          const isActive = team.line_items[0].count === poolData.max_players && teamInviteLink?.paid;
          return {
            ...team,
            isActive,
          };
        }) || [];

      const sortedTeams = teamsWithStatus.sort((a, b) => {
        // If one of the teams belongs to the logged-in user, it should be first
        if (a.owner_details?.id === loggedInUser) return -1;
        if (b.owner_details?.id === loggedInUser) return 1;

        // For other teams, sort by rank
        if (a.rank === null) return 1;
        if (b.rank === null) return -1;
        return a.rank - b.rank;
      });

      setTeams(sortedTeams);
    } catch (err) {
      await logError({
        filePath: "Pool/PoolPage.js",
        functionName: "fetchPoolData",
        errorType: "Fetch Error",
        errorMessage: err.message,
        stackTrace: err.stack,
        requestData: { poolId },
      });
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [poolId, loggedInUser]);


  useEffect(() => {
    fetchPoolData();

    // Subscribe to both InviteLink and Team changes
    const channel = supabase
      .channel("poolPageChanges")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "InviteLink",
          filter: `pool=eq.${poolId}`,
        },
        (payload) => {
          // Only handle paid status changes for InviteLink
          if (payload.eventType === "UPDATE" && payload.new?.paid !== payload.old?.paid) {
            // Update teams state directly without full reload
            setTeams(currentTeams => 
              currentTeams.map(team => {
                if (team.owner_details?.id === payload.new.person) {
                  return {
                    ...team,
                    isActive: payload.new.paid
                  };
                }
                return team;
              })
            );
          }
          // Skip full reload for all InviteLink changes when modals are open
          else if (!showModal && !showInviteModal && !showManageModal) {
            fetchPoolData();
          }
        }
      )
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "team",
          filter: `pool=eq.${poolId}`,
        },
        (payload) => {
          // Skip full reload for all team changes when modals are open
          if (!showModal && !showInviteModal && !showManageModal) {
            fetchPoolData();
          }
        }
      );

    // Enable realtime
    channel.subscribe((status) => {
      if (status === "SUBSCRIBED") {
        console.log("Subscribed to pool changes");
      }
    });

    return () => {
      supabase.removeChannel(channel);
    };
  }, [poolId, fetchPoolData, showModal, showInviteModal, showManageModal]);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  if (loading) {
    return (
      <div className="golf-pools-card">
        <div className="golf-pools-container">
          <div className="golf-pools-content">
            <div className="golf-pools-loading-container">
              <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="pool-page-error">Error: {error}</div>;
  }

  if (!pool) {
    return <div className="pool-page-error">Pool not found</div>;
  }

  return (
    <div className="golf-pools-card">
      <ToastContainer position="top-center" theme="dark" />
      <div className="golf-pools-container">
        <div className="golf-pools-header">
          <div className="golf-pools-header-row">
            <button onClick={() => navigate(-1)} className="golf-pools-back-link">
              <IoChevronBackOutline /> Back
            </button>
            <h1 className="golf-pools-title">{pool.name}</h1>
            <StatusBadge status={pool.tournament_status || 'Not Started'} />
          </div>
        </div>

        <div className="golf-pools-actions">
          <Link to={`/pool/${poolId}/team/${teams[0]?.id}`} className="golf-pools-action-button">
            <IoGolfOutline /> Team
          </Link>
          <Link to={`/leaderboard/${poolId}`} className="golf-pools-action-button">
            <FaTrophy /> Leaderboard
          </Link>
          {pool.owner === loggedInUser && (
            <button className="golf-pools-action-button" onClick={() => setShowModal(true)}>
              <IoSettingsOutline /> Manage
            </button>
          )}
        </div>

        <div className="stats-data-container">
          <div className="stat-card">
            <div className="golf-pools-leaderboard-label">Start Date</div>
            <div className="golf-pools-leaderboard-value">{formatDate(pool.tournament_fixture?.start_date)}</div>
          </div>
          <div className="stat-card">
            <div className="golf-pools-leaderboard-label">Teams</div>
            <div className="golf-pools-leaderboard-value">{pool.number_of_teams || 0}</div>
          </div>
          <div className="stat-card">
            <div className="golf-pools-leaderboard-label">Players Per Team</div>
            <div className="golf-pools-leaderboard-value">{pool.max_players || 0}</div>
          </div>
          <div className="stat-card">
            <div className="golf-pools-leaderboard-label">Players Scored</div>
            <div className="golf-pools-leaderboard-value">{pool.num_player_used_for_scoring || 0}</div>
          </div>
          <div className="stat-card">
            <div className="golf-pools-leaderboard-label">Cut Rule</div>
            <div className="golf-pools-leaderboard-value">{pool.all_players_must_make_cut ? "All Must Make Cut" : "No Cut Rule"}</div>
          </div>
          <div className="stat-card">
            <div className="golf-pools-leaderboard-label">Tie Breaker</div>
            <div className="golf-pools-leaderboard-value">{pool.tie_breaker === "Total Score" ? "Winning Score" : pool.tie_breaker}</div>
          </div>
        </div>

        <div className="teams-preview">
          <div className="golf-pools-sub-title">
            <h3>Teams</h3>
          </div>
          {teams.length > 0 ? (
            <div className="golf-pools-teams-grid">
              {teams.map((team) => (
                <div
                  key={team.id}
                  onClick={() => {
                    const timezone = pool.time_zone || "UTC";
                    const now = moment().tz(timezone);
                    const firstTeeTime = moment.utc(pool.first_tee_time).tz(timezone);
                    const tournamentStarted = now.isAfter(firstTeeTime);
                    const isOwner = team.owner_details?.id === loggedInUser;

                    if (!tournamentStarted && !isOwner) {
                      toast.info("Team details will be visible when the tournament begins", {
                        theme: "dark",
                        icon: "🏌️",
                      });

                      return;
                    }
                    navigate(`/pool/${poolId}/team/${team.id}`);
                  }}
                  className="golf-pools-team-card"
                >
                  <div className="golf-pools-team-header">
                    <div className="golf-pools-team-name-container">
                      <span className="golf-pools-team-name">{team.owner_details?.full_name}</span>
                      {team.rank === "1" && pool.tournament_status === "Official" && <IoTrophyOutline className="golf-pools-trophy-icon winner" title="Tournament Winner" />}
                    </div>
                    {team.isActive ? <MdOutlineAttachMoney className="golf-pools-paid-icon paid" title="Paid" /> : <MdMoneyOff className="golf-pools-paid-icon unpaid" title="Not Paid" />}
                  </div>
                  <div className="golf-pools-team-content">
                    <div className="golf-pools-team-stat">
                      <span className="golf-pools-team-label">Position</span>
                      <span className="golf-pools-team-value">{team.rank !== "MC" ? team.rank || "--" : <span className="golf-pools-mc-indicator">Cut</span>}</span>
                    </div>
                    <div className="golf-pools-team-stat">
                      <span className="golf-pools-team-label">Players</span>
                      <span className={`golf-pools-team-value ${team.line_items[0]?.count === pool.max_players ? "complete" : "incomplete"}`}>
                        {team.line_items[0]?.count || 0}/{pool.max_players || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="golf-pools-empty-container">No teams have joined this pool yet.</div>
          )}
        </div>

        {showModal && (
          <PoolDetailsModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            poolData={pool}
            mode="edit"
            loggedInUser={loggedInUser}
            refreshData={fetchPoolData}
            onTeamStatusChange={(updatedTeams) => setTeams(updatedTeams)}
          />
        )}

        {showInviteModal && (
          <Modal isOpen={showInviteModal} onClose={() => setShowInviteModal(false)} isSubmitting={isSubmitting}>
            <InviteToPool poolId={poolId} userId={loggedInUser} />
          </Modal>
        )}

        {showManageModal && (
          <Modal isOpen={showManageModal} onClose={() => setShowManageModal(false)} isSubmitting={isSubmitting}>
            <ManagePoolEntries poolId={poolId} canEdit={canEditRecord(pool.tournament_fixture?.start_date)} onTeamStatusChange={(updatedTeams) => setTeams(updatedTeams)} />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default PoolPage;
