import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AuthProvider, ProtectedRoute, useAuth } from "./Utilities/AuthContext.js";
import Login from "./LoginComponents/Login";
import ResetPassword from "./LoginComponents/ResetPassword";
import BackgroundTheme from "./ReusableComponents/BackgroundTheme";
import Navbar from "./ReusableComponents/Navbar";
import AllPools from "./Pool/AllPools.js";
import Team from "./Team/Team.js";
import Leaderboard from "./Leaderboard/Leaderboard.js";
import AllLeaderboard from "./Leaderboard/AllLeaderboards.js";
import Dashboard from "./DashboardComponents/Dashboard.js";
import PoolPage from "./Pool/PoolPage.js";
import AllTeamsView from './Team/AllTeamsView.js';
import FAQ from './FAQ/FAQ.js';
import LoadingSpinner from "./ReusableComponents/LoadingSpinner";

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

function AppContent() {
  const location = useLocation();
  const { isLoggedIn, isInitialized } = useAuth();
  
  // Add effect to handle page visibility changes
  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Force a re-render of the entire app when it becomes visible
        window.dispatchEvent(new Event('focus'));
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('pageshow', (e) => {
      if (e.persisted) {
        // Force a re-render when navigating back to the page
        window.dispatchEvent(new Event('focus'));
      }
    });

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const showNav = React.useMemo(() => {
    // Only show nav if logged in and not on auth-related pages
    return Boolean(isLoggedIn) && 
           !location.pathname.includes("/login") && 
           !location.pathname.includes("/reset-password");
  }, [location.pathname, isLoggedIn]);

  // Show loading state during initialization
  if (!isInitialized) {
    return (
      <div className="app-container loading">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="app-container">
      {showNav && <Navbar />}
      <div className={`main-content ${showNav ? 'with-navbar' : ''}`}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/" element={
            <ProtectedRoute>
              <BackgroundTheme>
                <Dashboard />
              </BackgroundTheme>
            </ProtectedRoute>
          } />
          <Route path="/home" element={
            <ProtectedRoute>
              <BackgroundTheme>
                <Dashboard />
              </BackgroundTheme>
            </ProtectedRoute>
          } />
          <Route path="/pools" element={
            <ProtectedRoute>
              <BackgroundTheme>
                <AllPools />
              </BackgroundTheme>
            </ProtectedRoute>
          } />
          <Route path="/pool/:poolId" element={
            <ProtectedRoute>
              <BackgroundTheme>
                <PoolPage />
              </BackgroundTheme>
            </ProtectedRoute>
          } />
          <Route path="/pool/:poolId/team/:teamId" element={
            <ProtectedRoute>
              <BackgroundTheme>
                <Team />
              </BackgroundTheme>
            </ProtectedRoute>
          } />
          <Route path="/teams" element={
            <ProtectedRoute>
              <BackgroundTheme>
                <AllTeamsView />
              </BackgroundTheme>
            </ProtectedRoute>
          } />
          <Route path="/leaderboard" element={
            <ProtectedRoute>
              <BackgroundTheme>
                <AllLeaderboard />
              </BackgroundTheme>
            </ProtectedRoute>
          } />
          <Route path="/leaderboard/:poolId" element={
            <ProtectedRoute>
              <BackgroundTheme>
                <Leaderboard />
              </BackgroundTheme>
            </ProtectedRoute>
          } />
          <Route path="/faq" element={
            <ProtectedRoute>
              <BackgroundTheme>
                <FAQ />
              </BackgroundTheme>
            </ProtectedRoute>
          } />
        </Routes>
      </div>
    </div>
  );
}

export default App;
