import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFetchPoolAndLineItemData } from "./TeamHelper";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { PacmanLoader } from "react-spinners";
import { IoChevronBackOutline } from "react-icons/io5";
import TeamViewForm from "./TeamViewForm";
import "../styles/golfPoolStyles.css";
import { supabase } from "../Utilities/supabaseClient";
import moment from "moment-timezone";
import { logError } from "../Utilities/errorLogger";
const Team = () => {
  const { poolId, teamId } = useParams();
  const navigate = useNavigate();
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const [content, setContent] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [formError, setFormError] = useState("");

  const {
    poolData: hookPoolData,
    teamLineItem: hookTeamLineItem,
    loading,
    error,
    isOwner: hookIsOwner,
  } = useFetchPoolAndLineItemData(poolId ? Number(poolId) : null, refetchTrigger, teamId ? Number(teamId) : null);

  const showError = error || formError;

  const hasTournamentStarted = useCallback((pool) => {
    if (!pool?.first_tee_time || !pool?.time_zone) return false;
    const firstTeeTime = moment.tz(pool.first_tee_time, pool.time_zone);
    const now = moment().tz(pool.time_zone);
    return now.isAfter(firstTeeTime);
  }, []);

  const renderContent = useCallback(async () => {
    if (!hookPoolData) return null;

    try {
      const { data: playerTournaments, error: playerError } = await supabase
        .from("playersTournaments")
        .select(
          `
          id,
          player:playersFixture!inner (
            id,
            player_name,
            world_ranking_position
          ),
          tournament
        `
        )
        .eq("tournament", hookPoolData.tournament_fixture.id)
        .not("player", "is", null)
        .order("player(player_name)", { ascending: true });

      if (playerError) {
        toast.error(`Error fetching player tournaments: ${playerError.message}`, { theme: "dark" });
        throw playerError;
      }

      if (!playerTournaments || playerTournaments.length === 0) {
        return (
          <div className="golf-pools-card">
            <div className="golf-pools-container">
              <div className="golf-pools-header">
                <div className="golf-pools-header-row">
                  <button onClick={() => navigate(-1)} className="golf-pools-back-link">
                    <IoChevronBackOutline /> Back
                  </button>
                  <div style={{ width: "80px" }}></div>
                </div>
                <p className="text-center">⛳ The tournament field has not been set yet. Please check back on Monday of tournament week when the official field is announced. 🏌️</p>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div className="golf-pools-card">
          <div className="golf-pools-container">
            <div className="details-card">
              <TeamViewForm
                records={hookTeamLineItem || []}
                isEditable={hookIsOwner && (!hasTournamentStarted(hookPoolData) || hookTeamLineItem?.[0]?.team?.owner?.can_create_late_team)}
                poolData={hookPoolData}
                setSpinner={setSpinner}
                setFormError={setFormError}
                setRefetchTrigger={setRefetchTrigger}
                playerTournaments={playerTournaments}
              />
            </div>
          </div>
        </div>
      );
    } catch (error) {
      await logError({
        filePath: "Team/Team.js",
        functionName: "renderContent",
        errorType: "Error",
        errorMessage: error.message,
        stackTrace: error.stack,
      });
      return (
        <div className="golf-pools-card">
          <div className="golf-pools-container">
            <div className="golf-pools-header">
              <div className="golf-pools-header-row">
                <button onClick={() => navigate(-1)} className="golf-pools-back-link">
                  <IoChevronBackOutline /> Back
                </button>
                <div style={{ width: "80px" }}></div>
              </div>
              <p className="golf-pools-empty-container">Error loading tournament data. Please try again later.</p>
            </div>
          </div>
        </div>
      );
    }
  }, [hookPoolData, hookTeamLineItem, hookIsOwner, hasTournamentStarted, navigate]);

  // Force re-render when teamLineItem changes
  useEffect(() => {
    if (!loading && hookTeamLineItem) {
      renderContent().then(setContent);
    }
  }, [loading, hookTeamLineItem, renderContent]);

  if (loading) {
    return (
      <div className="golf-pools-card">
        <div className="golf-pools-container">
          <div className="golf-pools-content">
            <div className="golf-pools-loading-container">
              <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showError) {
    return (
      <div className="golf-pools-card">
        <div className="golf-pools-container">
          <div className="golf-pools-header">
            <div className="golf-pools-header-row">
              <button onClick={() => navigate(-1)} className="golf-pools-back-link">
                <IoChevronBackOutline /> Back
              </button>
              <div style={{ width: "80px" }}></div>
            </div>
            <div className="golf-pools-error-container">{showError}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <ToastContainer position="top-center" />
      {spinner && (
        <div className="golf-pools-spinner-overlay">
          <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
        </div>
      )}
      {content}
    </>
  );
};

export default Team;
