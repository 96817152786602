import React, { useEffect, useCallback } from "react";
import { supabase } from '../ReusableComponents/supabaseClient';
import "../styles/golfPoolStyles.css";
import { MdErrorOutline } from "react-icons/md";
// import { getScoreClass } from './TeamViewFormHelpers';
import { logError } from "../Utilities/errorLogger";

function TeamLineItemsTable({ lineItems, teamId, poolData, onDataUpdate, isEditing = false, playerFixtures = [], selectedPlayers = [], onPlayerSelect }) {
  // Sort line items by tournament position, falling back to display order
  const sortedLineItems = React.useMemo(() => {
    return [...lineItems].sort((a, b) => {
      // If both have tournament positions, sort by that
      if (a.tournament_position && b.tournament_position) {
        // Handle T-prefixed positions
        const posA = parseInt(a.tournament_position.replace('T', '')) || 999;
        const posB = parseInt(b.tournament_position.replace('T', '')) || 999;
        if (posA !== posB) {
          return posA - posB;
        }
      }
      // If one has position and other doesn't, prioritize the one with position
      if (a.tournament_position && !b.tournament_position) return -1;
      if (!a.tournament_position && b.tournament_position) return 1;
      
      // Fall back to display order
      return (a.display_order || 0) - (b.display_order || 0);
    });
  }, [lineItems]);

  // Fetch updated data when changes occur
  const fetchUpdatedData = useCallback(async () => {
    if (!teamId) return;

    try {
      const { data, error } = await supabase
        .from('teamLineItem')
        .select(`
          id,
          player:player (
            player_name,
            world_ranking_position
          ),
          tournament_position,
          total_score_to_par,
          players_tournament_status,
          display_order
        `)
        .eq('team', teamId);

      if (error) throw error;
      if (onDataUpdate) onDataUpdate(data);
    } catch (error) {
      await logError({
        filePath: "Team/TeamLineItemsTable.js",
        functionName: "fetchUpdatedData",
        errorType: "Error",
        errorMessage: error.message,
        stackTrace: error.stack,
      });
      console.error('Error fetching updated line items:', error);
    }
  }, [teamId, onDataUpdate]);

  // Set up real-time subscriptions
  useEffect(() => {
    if (!teamId) return;

    // Subscribe to changes in the teamLineItem table for this team
    const lineItemSubscription = supabase
      .channel('team_line_item_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'teamLineItem',
          filter: `team=eq.${teamId}`
        },
        (payload) => {
          fetchUpdatedData();
        }
      )
      .subscribe();

    // Cleanup subscription on unmount
    return () => {
      lineItemSubscription.unsubscribe();
    };
  }, [teamId, fetchUpdatedData]);

  const getScoreClass = (score) => {
    if (!score || score === "--" || score === "-") return "";
    if (score === "E") return "score-even";
    const numScore = parseInt(score);
    if (isNaN(numScore)) return "";
    return numScore < 0 ? "score-under" : "score-over";
  };

  const formatScore = (score) => {
    if (!score || score === "--" || score === "-") return "--";
    if (score === "E") return "E";
    const numScore = parseInt(score);
    if (isNaN(numScore)) return score;
    return numScore > 0 ? `+${numScore}` : numScore;
  };

  const getPlayerStatus = (item, index) => {
    if (item.players_tournament_status === "CUT") return "golf-pools-missed-cut";
    if (item.players_tournament_status === "WD") return "golf-pools-withdrawn";
    if (index >= poolData.num_player_used_for_scoring && poolData.tournament_status !== "Not Started") {
      return "golf-pools-not-counting";
    }
    return "";
  };

  return (
    <div className="team-table-container desktop-only">
      <table className="team-view-table">
        <thead>
          <tr>
            <th className="player-column">Player</th>
            <th className="rank-column">World Ranking</th>
            <th className="position-column">Position</th>
            <th className="score-column">To Par</th>
          </tr>
        </thead>
        <tbody>
          {sortedLineItems.map((item, index) => {
            const player = selectedPlayers[index];
            const isPlayerEmpty = !player?.name;
            const currentPlayerId = player?.id?.toString() || "";

            return (
              <tr key={index} className={getPlayerStatus(item, index)}>
                <td className="player-column">
                  {isEditing ? (
                    <select 
                      className="team-card-select" 
                      onChange={(e) => onPlayerSelect(index, e)} 
                      value={currentPlayerId}
                    >
                      <option value="">Select a Player</option>
                      {playerFixtures.map((fixture) => {
                        const isSelected = currentPlayerId === fixture.playerId.toString();
                        const isDisabled = selectedPlayers.some(
                          (p) => p?.id?.toString() === fixture.playerId.toString() && !isSelected
                        );
                        return (
                          <option
                            key={fixture.key}
                            value={fixture.playerId}
                            data-player-id={fixture.playerId}
                            data-player-name={fixture.playerName}
                            data-world-ranking={fixture.worldRanking}
                            disabled={isDisabled}
                          >
                            {fixture.value}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    isPlayerEmpty ? (
                      <span className="not-selected-text" style={{ color: '#dc2626' }}>
                        <MdErrorOutline className="not-selected-icon" />
                        Player Not Selected
                      </span>
                    ) : player.name
                  )}
                </td>
                <td className="rank-column text-center">{player?.worldRanking || "--"}</td>
                <td className="position-column text-center">{item.tournament_position || "--"}</td>
                <td className={`score-column text-center ${getScoreClass(item.total_score_to_par)}`}>
                  {item.total_score_to_par || "--"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(TeamLineItemsTable); 