import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { supabase } from "../ReusableComponents/supabaseClient";
import { logError } from '../Utilities/errorLogger';

export function useFetchUser() {
  const [loading, setLoading] = useState(false);

  const fetchUser = async (email) => {
    setLoading(true);
    try {
      let { data, error } = await supabase.from("user").select("id, email, password, full_name").eq("email", email.toLowerCase()).single();

      if (error) {
        toast.error(error.message || "Failed to fetch user", { theme: "dark" });
        return null;
      }

      return data;
    } catch (error) {
      await logError({
        filePath: 'LoginComponents/ResetPassword.js',
        functionName: 'handleSubmit',
        errorType: 'Password Reset Error',
        errorMessage: error.message,
        stackTrace: error.stack,
        requestData: { error }
      });
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { fetchUser, loading };
}
