import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../ReusableComponents/supabaseClient";
import { IoChevronBackOutline } from "react-icons/io5";
import { PacmanLoader } from "react-spinners";
import StatusBadge from "../ReusableComponents/StatusBadge";
import moment from "moment";
import "../styles/golfPoolStyles.css";
import { logError } from '../Utilities/errorLogger';

const AllLeaderboard = () => {
  const [pools, setPools] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const loggedInUser = Number(localStorage.getItem("userId"));

  useEffect(() => {
    const fetchPools = async () => {
      try {
        setLoading(true);

        // First get pools where user is owner
        const { data: ownerPools, error: ownerError } = await supabase
          .from("pool")
          .select(
            `
            id,
            name,
            current_round,
            round_status,
            tournament_status,
            tournament_fixture (
              id,
              name,
              start_date
            )
          `
          )
          .eq("owner", loggedInUser);

        if (ownerError) throw ownerError;

        // Then get pools where user is invited
        const { data: invitedPools, error: inviteError } = await supabase
          .from("InviteLink")
          .select(
            `
            pool (
              id,
              name,
              current_round,
              round_status,
              tournament_status,
              tournament_fixture (
                id,
                name,
                start_date
              )
            )
          `
          )
          .eq("person", loggedInUser);

        if (inviteError) throw inviteError;

        // Combine and deduplicate pools
        const allPools = [...(ownerPools || []), ...(invitedPools?.map((ip) => ip.pool) || [])];

        // Remove duplicates based on pool id and sort by id
        const uniquePools = Array.from(new Map(allPools.map((pool) => [pool.id, pool])).values())
          .sort((a, b) => a.id - b.id);

        setPools(uniquePools || []);
      } catch (error) {
        console.error("Error fetching pools:", error);
        await logError({
          filePath: 'Leaderboard/AllLeaderboards.js',
          functionName: 'fetchPools',
          errorType: 'Pools Fetch Error',
          errorMessage: error.message,
          stackTrace: error.stack,
          requestData: { loggedInUser }
        });
      } finally {
        setLoading(false);
      }
    };

    if (loggedInUser) {
      fetchPools();
    }
  }, [loggedInUser]);

  const handleRowClick = (poolId) => {
    navigate(`/leaderboard/${poolId}`);
  };

  if (loading) {
    return (
      <div className="golf-pools-card">
        <div className="golf-pools-container">
          <div className="golf-pools-content">
            <div className="golf-pools-loading-container">
              <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderLeaderboardsTable = () => (
    <div className="golf-pools-table-wrapper desktop-only">
      <table className="golf-pools-table">
        <thead>
          <tr>
            <th>Pool #</th>
            <th>Tournament</th>
            <th>Start Date</th>
            <th className="text-center">Round</th>
            <th className="text-center">Round Status</th>
            <th className="text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          {pools.map((pool) => (
            <tr key={pool.id} onClick={() => handleRowClick(pool.id)} className="clickable">
              <td>{pool.id}</td>
              <td className="alignLeft truncate">{pool.tournament_fixture?.name || pool.name}</td>
              <td>{moment(pool.tournament_fixture?.start_date).format("MM/DD/YY")}</td>
              <td className="text-center">{pool.current_round || "-"}</td>
              <td className="text-center">
                <div className="status-badge-container">
                  <StatusBadge status={pool.round_status || 'Not Started'} />
                </div>
              </td>
              <td className="text-center">
                <div className="status-badge-container">
                  <StatusBadge status={pool.tournament_status || 'Not Started'} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderLeaderboardsCards = () => (
    <div className="pools-cards-container mobile-only">
      {pools.map((pool) => (
        <div 
          key={pool.id}
          className="pool-card"
          onClick={() => handleRowClick(pool.id)}
        >
          <div className="pool-card-header">
            <div className="item-header">
              <h3>{pool.tournament_fixture?.name || pool.name}</h3>
              <StatusBadge status={pool.tournament_status || 'Not Started'} />
            </div>
          </div>
          <div className="pool-card-content">
            <div className="pool-card-detail">
              <span className="detail-label">Pool #:</span>
              <span className="detail-value">{pool.id}</span>
            </div>
            <div className="pool-card-detail">
              <span className="detail-label">Date:</span>
              <span className="detail-value">
                {pool.tournament_fixture?.start_date 
                  ? moment(pool.tournament_fixture.start_date).format("MM/DD/YY")
                  : 'N/A'}
              </span>
            </div>
            <div className="pool-card-detail">
              <span className="detail-label">Round:</span>
              <span className="detail-value">{pool.current_round || "-"}</span>
            </div>
            <div className="pool-card-detail">
              <span className="detail-label">Round Status:</span>
              <StatusBadge status={pool.round_status || 'Not Started'} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="golf-pools-card">
      <div className="golf-pools-container">
        <div className="golf-pools-header">
          <div className="golf-pools-header-row">
            <button onClick={() => navigate(-1)} className="golf-pools-back-link">
              <IoChevronBackOutline /> Back
            </button>
            <h1 className="golf-pools-title">My Leaderboards</h1>
            <div style={{ width: "80px" }}></div>
          </div>
        </div>
        {pools.length > 0 ? (
          <>
            {renderLeaderboardsTable()}
            {renderLeaderboardsCards()}
          </>
        ) : (
          <div className="golf-pools-empty-container">
            <p>No leaderboards available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllLeaderboard;
