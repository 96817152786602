import React, { useEffect, useState } from "react";
import "./ManagePoolEntries.css";
import { fetchInviteLinksByPool, updateInviteLinkPaidStatus, resendInviteEmail } from "./PoolModalHelper";
import { MdOutlineMail } from "react-icons/md";
import { PacmanLoader } from "react-spinners";
import moment from "moment";
import { toast } from "react-toastify";
import "moment-timezone";
import { logError } from '../Utilities/errorLogger';

const ManagePoolEntries = ({ poolId, canEdit }) => {
  const [inviteLinks, setInviteLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const loadInviteLinks = async () => {
      if (!initialLoad) return;
      
      try {
        const data = await fetchInviteLinksByPool(poolId);
        setInviteLinks(data);
      } catch (error) {
        await logError({
          filePath: 'Pool/ManagePoolEntries.js',
          functionName: 'loadInviteLinks',
          errorType: 'Invite Links Fetch Error',
          errorMessage: error.message,
          stackTrace: error.stack,
          requestData: { poolId }
        });
        toast.error("Failed to fetch invite links", { theme: "dark" });
      } finally {
        setLoading(false);
        setInitialLoad(false);
      }
    };

    loadInviteLinks();
  }, [poolId, initialLoad]);

  const handlePaidChange = async (id, paid) => {
    try {
      // Update local state immediately
      setInviteLinks(currentLinks => 
        currentLinks.map(link => 
          link.id === id ? { ...link, paid } : link
        )
      );

      // Update the database silently
      const result = await updateInviteLinkPaidStatus(id, paid);
      if (!result) {
        // Only revert the local state if the update failed
        setInviteLinks(currentLinks => 
          currentLinks.map(link => 
            link.id === id ? { ...link, paid: !paid } : link
          )
        );
        toast.error("Failed to update paid status", { theme: "dark" });
      }
    } catch (error) {
      // Revert local state on error
      setInviteLinks(currentLinks => 
        currentLinks.map(link => 
          link.id === id ? { ...link, paid: !paid } : link
        )
      );
      
      await logError({
        filePath: 'Pool/ManagePoolEntries.js',
        functionName: 'handlePaidChange',
        errorType: 'Paid Status Update Error',
        errorMessage: error.message,
        stackTrace: error.stack,
        requestData: { id, paid }
      });
      toast.error("Failed to update paid status", { theme: "dark" });
    }
  };

  const handleResendEmail = async (email, poolId) => {
    try {
      const response = await resendInviteEmail(email, poolId);
      if (response.ok) {
        toast.success("Email resent successfully!", { theme: "dark" });
      } else {
        throw new Error("Failed to resend email");
      }
    } catch (error) {
      await logError({
        filePath: 'Pool/ManagePoolEntries.js',
        functionName: 'handleResendEmail',
        errorType: 'Email Resend Error',
        errorMessage: error.message,
        stackTrace: error.stack,
        requestData: { email, poolId }
      });
      toast.error("Failed to resend email", { theme: "dark" });
    }
  };

  return (
    <div className="manage-entries-container">
      {loading && initialLoad ? (
        <div className="golf-pools-loading-container">
          <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
        </div>
      ) : (
        <div className="manage-entries-table-container">
          <table className="manage-entries-table">
            <thead>
              <tr>
                <th>Person</th>
                <th>Sent</th>
                <th>User</th>
                <th>Paid</th>
                <th>Resend</th>
              </tr>
            </thead>
            <tbody>
              {inviteLinks.length > 0 ? (
                inviteLinks.map((link) => (
                  <tr key={link.id}>
                    <td>{link.person?.full_name || link.email}</td>
                    <td>{moment(link.invite_sent).format("MM/DD/YY")}</td>
                    <td>{link.user ? "Yes" : "No"}</td>
                    <td>
                      <input 
                        type="checkbox" 
                        className="manage-entries-checkbox" 
                        checked={link.paid} 
                        onChange={(e) => handlePaidChange(link.id, e.target.checked)}
                      />
                    </td>
                    <td>
                      <div className="resend-button-container">
                        <button 
                          className="manage-entries-button" 
                          onClick={() => handleResendEmail(link.email, link.pool?.id)} 
                          disabled={!canEdit}
                        >
                          <MdOutlineMail size={20} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ManagePoolEntries;
