import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import TeamTable from "./TeamTable";
import { supabase } from "../ReusableComponents/supabaseClient";
import { PacmanLoader } from "react-spinners";
import StatusBadge from "../ReusableComponents/StatusBadge";
import { toast } from "react-toastify";
import { useLeaderboardData, fetchPoolDetailsById } from "./LeaderboardHelper";
import moment from "moment-timezone";
import "../styles/golfPoolStyles.css";
import { logError } from "../Utilities/errorLogger";
import "../Team/TeamViewForm.css";

function Leaderboards() {
  const { poolId } = useParams();
  const navigate = useNavigate();
  const [poolData, setPoolData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { teams, teamLineItems, loading: leaderboardLoading } = useLeaderboardData(poolId);
  const [userId] = useState(Number(localStorage.getItem("userId")));

  const isTournamentStarted = useMemo(() => {
    if (!poolData) return false;

    const timezone = poolData.time_zone || "UTC";
    const now = moment().tz(timezone);
    const firstTeeTime = poolData.first_tee_time ? moment.utc(poolData.first_tee_time).tz(timezone) : moment.utc(poolData.tournament_fixture?.start_date).tz(timezone).startOf("day").add(5, "hours");

    const tournamentStarted = now.isAfter(firstTeeTime);

    // Consider tournament started if status is not "Not Started"
    return tournamentStarted || (poolData.tournament_status && poolData.tournament_status !== "Not Started");
  }, [poolData]);

  const canViewTeam = useCallback(
    (team) => {
      const canView = isTournamentStarted || team.owner?.id === userId;

      return canView;
    },
    [userId, isTournamentStarted]
  );

  useEffect(() => {
    setLoading(true);
    fetchPoolDetailsById(poolId)
      .then((data) => {
        setPoolData(data);
        setLoading(false);
      })
      .catch((err) => {
        logError({
          filePath: "Leaderboard/AllLeaderboards.js",
          functionName: "fetchPools",
          errorType: "Pools Fetch Error",
          errorMessage: error.message,
          stackTrace: error.stack,
          requestData: { poolId },
        });

        console.error("Error fetching pool details:", err.message);
        setError("Failed to fetch pool details");
        toast.error("Failed to fetch pool details: " + err.message, { theme: "dark" });
        setLoading(false);
      });

    const poolTableSubscription = supabase
      .channel("poolTableSubscription")
      .on("postgres_changes", { event: "UPDATE", schema: "public", table: "pool", filter: `id=eq.${poolId}` }, (payload) => {
        setPoolData(payload.new);
      })
      .subscribe();

    return () => {
      supabase.removeChannel(poolTableSubscription);
    };
  }, [poolId, error.message, error.stack]);

  if (loading || leaderboardLoading) {
    return (
      <div className="golf-pools-card">
        <div className="golf-pools-container">
          <div className="golf-pools-content">
            <div className="golf-pools-loading-container">
              <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="golf-pools-empty-container">{error}</div>;
  }

  return (
    <div className="golf-pools-card">
      <div className="golf-pools-container">
        {teamLineItems.length > 0 ? (
          <>
            {poolData && (
              <>
                <div className="golf-pools-header">
                  <div className="golf-pools-header-row">
                    <button onClick={() => navigate(-1)} className="golf-pools-back-link">
                      <IoChevronBackOutline /> Back
                    </button>
                    <h2 className="golf-pools-title">{poolData.name}</h2>
                    <div style={{ width: "80px" }}></div>
                  </div>
                </div>
                <div className="tournament-stats">
                    <div className="stat-item">
                      <div className="stat-content">
                        <div className="stat-title">Tournament Status</div>
                        <StatusBadge status={poolData.tournament_status || 'Not Started'} />
                      </div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-content">
                        <div className="stat-title">Round Status</div>
                        <StatusBadge status={poolData.round_status || 'Not Started'} />
                      </div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-content">
                        <div className="stat-title">Current Round</div>
                        <div className="stat-text">{poolData.current_round || '--'}</div>
                      </div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-content">
                        <div className="stat-title">Cut Line</div>
                        <div className="stat-text">{poolData.cut_value || '--'}</div>
                      </div>
                    </div>
                  </div>
              </>
            )}
            <TeamTable teams={teams} teamLineItems={teamLineItems} canViewTeam={canViewTeam} isTournamentStarted={isTournamentStarted} />
          </>
        ) : (
          <>
            <div className="golf-pools-header">
              <div className="golf-pools-header-row">
                <button onClick={() => navigate(-1)} className="golf-pools-back-link">
                  <IoChevronBackOutline /> Back
                </button>
                <h2 className="golf-pools-title">Leaderboard</h2>
                <div style={{ width: "80px" }}></div>
              </div>
            </div>
            <div className="golf-pools-empty-container">Fore! 🏌️ Looks like there are no teams registered for this pool yet ⛳</div>
          </>
        )}
      </div>
    </div>
  );
}

export default Leaderboards;
