import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = ({ containerClassName = '' }) => {
  return (
    <div className={`loading-spinner-container ${containerClassName}`}>
      <div className="pacman">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingSpinner; 