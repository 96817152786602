import React, { useState } from 'react';
import { FiChevronDown, FiPlus, FiMinus } from 'react-icons/fi';
import './FAQ.css';

function FAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      category: "Pool Management",
      items: [
        {
          question: "How do I create a new pool?",
          answer: "To create a new pool, navigate to the 'Dashboard' or the 'Pools' tab and click the '+ New Pool' button. Then fill in the required information including pool name, tournament, and settings."
        },
        {
          question: "How do I invite friends to my pool?",
          answer: "To invite friends, go to 'My Pools' and select the pool you want to share. Click the 'Manage' button, then 'Invite Friends', and enter your friends' email addresses. They will receive an email invitation with a link to join your pool."
        },
        {
          question: "How do I make a team paid as pool owner?",
          answer: "Navigate to the pool by clicking on it from the dashboard or pool page. Click on 'Manage', select 'Manage Entries', and click the paid checkbox next to the team you want to mark as paid."
        },
        {
          question: "What happens if I change the tournament for my pool?",
          answer: "When you change the tournament for a pool, all existing team entries will be deleted. This is because each tournament has a different field of players, so teams need to be recreated with players from the new tournament's roster."
        }
      ]
    },
    {
      category: "Team Management",
      items: [
        {
          question: "How do I create and manage my team?",
          answer: "Go to 'My Teams' and select the team you want to manage. Click the 'Edit' button to modify your team. From there, you can select golfers from the available roster. Remember, you can only edit your team before the tournament begins."
        },
        {
          question: "Why can't I edit my pool or team?",
          answer: "Pools and teams can only be edited before the tournament begins. Once a tournament starts, all pools and teams are locked to ensure fair play."
        },
        {
          question: "Why are all teams not showing on the leaderboard?",
          answer: "Teams may not appear on the leaderboard if they are not marked as paid or if they don't have the correct number of players selected. Make sure your team is paid and has the required number of players to appear on the leaderboard."
        }
      ]
    },
    {
      category: "Scoring & Leaderboard",
      items: [
        {
          question: "How does scoring work?",
          answer: "Scoring is based on your selected golfers' tournament performance. Points are awarded for finishing positions and other achievements. You can view the specific scoring rules for your pool in the pool details section."
        },
        {
          question: "When are leaderboards updated?",
          answer: "Leaderboards are updated in real-time during tournaments. To view current standings, click on 'Leaderboards' in the navigation bar and select your pool's leaderboard."
        },
        {
          question: "What happens if all teams miss the cut?",
          answer: "In the rare case where all teams miss the cut, the app will automatically reduce the number of players used for scoring by one to ensure a winner can be determined."
        }
      ]
    },
    {
      category: "App Usage",
      items: [
        {
          question: "How do I add the app to my phone's home screen?",
          answer: "From your phone's web browser, open the app and tap the 'More' button (usually three dots). Then select 'Add to Home Screen' from the menu. This will create an app icon on your home screen for quick access."
        }
      ]
    }
  ];

  const toggleQuestion = (categoryIndex, questionIndex) => {
    const newIndex = openIndex === `${categoryIndex}-${questionIndex}` ? null : `${categoryIndex}-${questionIndex}`;
    setOpenIndex(newIndex);
  };

  return (
    <div className="faq-container">
      <div className="faq-card">
        <div className="faq-header">
          <h1 className="faq-title">Frequently Asked Questions</h1>
          <p className="faq-subtitle">Find answers to common questions about Golf Pools</p>
        </div>

        <div className="faq-categories">
          {faqs.map((category, categoryIndex) => (
            <div key={categoryIndex} className="faq-category">
              <h2>{category.category}</h2>
              
              {category.items.map((faq, questionIndex) => (
                <div key={questionIndex} className="faq-question">
                  <button
                    onClick={() => toggleQuestion(categoryIndex, questionIndex)}
                  >
                    <span>{faq.question}</span>
                    {openIndex === `${categoryIndex}-${questionIndex}` ? 
                      <FiMinus size={20} color="#1a472a" /> : 
                      <FiPlus size={20} color="#1a472a" />
                    }
                  </button>
                  
                  <div className="faq-answer" style={{
                    maxHeight: openIndex === `${categoryIndex}-${questionIndex}` ? '500px' : '0',
                  }}>
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQ; 
