import { supabase } from './supabaseClient';

/**
 * Logs a frontend error to the database
 * @param {Object} params - The error logging parameters
 * @param {string} params.filePath - The path of the file where the error occurred
 * @param {string} params.functionName - The name of the function where the error occurred
 * @param {string} params.errorType - The type of error
 * @param {string} params.errorMessage - The error message
 * @param {string} [params.stackTrace] - The error stack trace
 * @param {Object} [params.requestData] - Any relevant request data
 * @param {Object} [params.responseData] - Any relevant response data
 * @param {string} [params.severity] - The severity of the error (defaults to "error")
 * @returns {Promise<Object>} The created error log record
 */
export const logError = async ({
    filePath,
    functionName,
    errorType,
    errorMessage,
    stackTrace = null,
    requestData = null,
    responseData = null,
    severity = 'error'
}) => {
    try {
        const userId = JSON.parse(localStorage.getItem('userId'));
        const { data, error } = await supabase
            .from('apierrorlog')
            .insert([{
                file_path: filePath,
                function_name: functionName,
                error_type: errorType,
                error_message: errorMessage,
                stack_trace: stackTrace,
                request_data: requestData,
                response_data: responseData,
                user_id: userId,
                environment: process.env.NODE_ENV || 'development',
                severity
            }])
            .select()
            .single();

        if (error) {
            console.error('Error logging frontend error:', error);
            return null;
        }

        return data;
    } catch (error) {
        console.error('Failed to log frontend error:', error);
        return null;
    }
}; 