import React, { useState } from "react";
import { logError } from "../Utilities/errorLogger";
function ContactForm({ closeModal, onSubmissionStatusChange }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [error, setError] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setError(null); // Clear any previous errors when user makes changes
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    onSubmissionStatusChange(true);
    
    try {
      const response = await fetch("/api/email/sendContactUsEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
        closeModal();
      } else {
        setError(data.message || 'Failed to send message. Please try again.');
      }
    } catch (error) {
      await logError({
        filePath: 'ReusableComponents/ContactUsForm.js',
        functionName: 'handleSubmit',
        errorType: 'Error',
        errorMessage: error.message,
        stackTrace: error.stack,
      });
      console.error("Error sending email:", error);
      setError('Failed to send message. Please try again later.');
    } finally {
      onSubmissionStatusChange(false);
    }
  };

  return (
    <>
      <h2 className="form-title">Contact Us</h2>
      {error && (
        <div className="error-message" style={{ color: 'red', marginBottom: '1rem' }}>
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit} className="loginForm">
        <div className="form-group">
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            className="inputStyle"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            className="inputStyle"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            className="inputStyle"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            className="inputStyle"
            style={{ minHeight: "120px", resize: "vertical" }}
            onChange={handleChange}
            required
          />
        </div>
        <button
          type="submit"
          className="submitButton"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Send Message
        </button>
      </form>
    </>
  );
}

export default ContactForm;
