import React, { useEffect, useState } from "react";
import { fetchTournamentOptions, canEditRecord } from "./PoolModalHelper";
import { MdErrorOutline } from "react-icons/md";
import moment from "moment";
import "./PoolForm.css";
import { supabase } from "../ReusableComponents/supabaseClient";
import { PacmanLoader } from "react-spinners";
import { toast } from "react-toastify";
import { logError } from "../Utilities/errorLogger";

const PoolForm = ({ poolData, mode, setFormData, canEdit, setCanEdit, activeTab, errors, setErrors }) => {
  const [formState, setFormState] = useState({
    tournament: poolData?.tournament_fixture?.id || "",
    teamSize: poolData?.max_players || "5",
    numPlayersScored: poolData?.num_player_used_for_scoring || "5",
    allMakeCut: poolData?.all_players_must_make_cut || false,
    tieBreaker: poolData?.tie_breaker || "Select Tie Breaker",
    poolId: poolData?.id || null,
    tour: poolData?.tour_fixtures || "10", // Default to PGA Tour (ID: 1)
  });

  const [tournaments, setTournaments] = useState([]);
  const [tours, setTours] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [pendingTournamentChange, setPendingTournamentChange] = useState(null);
  const [selectedTournament, setSelectedTournament] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        // Load tours
        const { data: toursData, error: toursError } = await supabase.from("tourFixture").select("id, tour_id, tour_name").order("tour_name", { ascending: true });

        if (toursError) throw toursError;

        // Find PGA Tour for default
        const pgaTour = toursData.find((t) => t.tour_id === 1) || toursData[0];
        setTours(toursData);

        // Set default tour if not already set
        if (!formState.tour) {
          setFormState((prev) => ({
            ...prev,
            tour: pgaTour?.id?.toString() || "1",
          }));
        }

        // Load tournaments based on selected tour
        const selectedTour = toursData.find((t) => t.id === parseInt(formState.tour)) || pgaTour;

        if (selectedTour) {
          const tournamentData = await fetchTournamentOptions(poolData?.tournament_fixture?.tournament_id, selectedTour.tour_id);
          setTournaments(tournamentData);

          if (poolData?.tournament_fixture?.tournament_id) {
            const matchingTournament = tournamentData.find((t) => t.tournament_id === poolData.tournament_fixture.tournament_id);
            if (matchingTournament) {
              setSelectedTournament(matchingTournament);
              setFormState((prev) => ({
                ...prev,
                tournament: matchingTournament.id,
              }));
            }
          }
        }
      } catch (error) {
        await logError({
          filePath: 'Pool/PoolForm.js',
          functionName: 'loadData',
          errorType: 'Data Loading Error',
          errorMessage: error.message,
          stackTrace: error.stack,
        });
        console.error("Error loading data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (activeTab === "edit" || !activeTab) {
      loadData();
    }
  }, [activeTab, poolData?.tournament_fixture?.tournament_id, formState.tour]);

  // Update canEdit based on selected tournament
  useEffect(() => {
    if (mode === "edit" && poolData) {
   
      // Always check editability with current pool data
      const canEditValue = canEditRecord(poolData);

      // If tournament is selected, also check that
      if (selectedTournament) {
        const poolWithSelectedTournament = {
          ...poolData,
          tournament_fixture: {
            ...poolData.tournament_fixture,
            ...selectedTournament
          },
          first_tee_time: selectedTournament.first_tee_time,
          time_zone: selectedTournament.time_zone
        };
        
        const canEditWithNewTournament = canEditRecord(poolWithSelectedTournament);
        
        setCanEdit(canEditValue && canEditWithNewTournament);
      } else {
        setCanEdit(canEditValue);
      }
    }
  }, [selectedTournament, mode, poolData, setCanEdit]);

  useEffect(() => {
    if (setFormData) {
      setFormData(formState);
    }
  }, [formState, setFormData]);

  const handleInputChange = async (e) => {
    // Prevent changes if editing is disabled
    if (mode === "edit" && !canEdit) {
      e.preventDefault();
      return;
    }

    const { name, value, type, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;

    // Add validation for number inputs
    if (type === "number") {
      const numValue = parseInt(value);
      if (numValue < 1) {
        newValue = "1";
      } else if (numValue > 15) {
        newValue = "15";
      }
    }

    // Show confirmation dialog when changing tournament in edit mode
    if (name === "tournament" && mode === "edit" && poolData?.id) {
      // Get the selected tournament details
      const tournament = tournaments.find(t => t.id === parseInt(value));
      if (tournament) {
        const poolWithNewTournament = {
          ...poolData,
          tournament_fixture: {
            ...poolData.tournament_fixture,
            ...tournament
          },
          first_tee_time: tournament.first_tee_time,
          time_zone: tournament.time_zone
        };
        
        const canEditNewTournament = canEditRecord(poolWithNewTournament);

        if (!canEditNewTournament) {
          toast.error("Cannot change to a tournament that has already started", { theme: "dark" });
          return;
        }

        setPendingTournamentChange(newValue);
        setShowConfirmation(true);
        return;
      }
    }

    setFormState((prev) => ({
      ...prev,
      [name]: newValue,
    }));

    // Clear error for this field when it's changed
    if (errors && errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: undefined,
      }));
    }
  };

  const handleConfirmTournamentChange = async () => {
    try {
      // Delete all teamLineItem records for this pool
      const { error: deleteError } = await supabase
        .from('teamLineItem')
        .delete()
        .eq('pool', poolData.id);

      if (deleteError) {
        throw deleteError;
      }

      // Update the form state with the new tournament
      setFormState((prev) => ({
        ...prev,
        tournament: pendingTournamentChange,
      }));

      toast.success("Tournament changed and team selections cleared", { theme: "dark" });
    } catch (error) {
      await logError({
        filePath: 'Pool/PoolForm.js',
        functionName: 'handleConfirmTournamentChange',
        errorType: 'Tournament Change Error',
        errorMessage: error.message,
        stackTrace: error.stack,
      });
      console.error("Error deleting team selections:", error);
      toast.error("Failed to change tournament. Please try again.", { theme: "dark" });
    } finally {
      setShowConfirmation(false);
      setPendingTournamentChange(null);
    }
  };

  const handleCancelTournamentChange = () => {
    setShowConfirmation(false);
    setPendingTournamentChange(null);
  };

  return (
    <div className={`pool-form-container ${(mode === "edit" || mode === "insert") && activeTab === "edit" ? "pool-modal-body" : ""}`}>
      {isLoading ? (
        <div className="golf-pools-loading-container">
          <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
        </div>
      ) : (
        <>
          {mode === "edit" && !canEdit && (
            <div className="edit-disabled-message">
              <p className="error-text">Tournament has already started. Editing is disabled.</p>
            </div>
          )}
          <form className="pool-form" onSubmit={(e) => e.preventDefault()}>
            <div className="pool-form-group">
              <label className="form-label" htmlFor="tour">
                Tour:
              </label>
              <select id="tour" name="tour" value={formState.tour} onChange={handleInputChange} disabled={mode === "edit" && !canEdit} required className={errors?.tour ? "form-select error" : "form-select"}>
                {tours.map((tour) => (
                  <option key={tour.id} value={tour.id}>
                    {tour.tour_name}
                  </option>
                ))}
              </select>
              {errors?.tour && (
                <div className="pool-form-error-message">
                  <MdErrorOutline className="pool-form-error-icon" size={16} />
                  <span>{errors.tour}</span>
                </div>
              )}
            </div>

            <div className="pool-form-group">
              <label className="form-label" htmlFor="tournament">
                Tournament:
              </label>
              <select
                id="tournament"
                name="tournament"
                value={formState.tournament}
                onChange={handleInputChange}
                disabled={mode === "edit" && !canEdit}
                required
                className={errors?.tournament ? "form-select error" : "form-select"}
              >
                <option value="">Select Tournament</option>
                {tournaments.map((tournament) => (
                  <option key={tournament.id} value={tournament.id}>
                    {tournament.name} - {moment(tournament.start_date).format("MM/DD/YY")}
                  </option>
                ))}
              </select>
              {errors?.tournament && (
                <div className="pool-form-error-message">
                  <MdErrorOutline className="pool-form-error-icon" size={16} />
                  <span>{errors.tournament}</span>
                </div>
              )}
            </div>

            <div className="pool-form-group">
              <label className="form-label" htmlFor="teamSize">
                Players Per Team:
              </label>
              <input
                type="number"
                id="teamSize"
                name="teamSize"
                value={formState.teamSize}
                onChange={handleInputChange}
                disabled={mode === "edit" && !canEdit}
                min="1"
                max="15"
                step="1"
                required
                className={errors?.teamSize ? "form-input error" : "form-input"}
              />
              {errors?.teamSize && (
                <div className="pool-form-error-message">
                  <MdErrorOutline className="pool-form-error-icon" size={16} />
                  <span>{errors.teamSize}</span>
                </div>
              )}
            </div>

            <div className="pool-form-group">
              <label className="form-label" htmlFor="numPlayersScored">
                Players Used For Scoring:
              </label>
              <input
                type="number"
                id="numPlayersScored"
                name="numPlayersScored"
                value={formState.numPlayersScored}
                onChange={handleInputChange}
                disabled={mode === "edit" && !canEdit}
                min="1"
                max="15"
                step="1"
                required
                className={errors?.numPlayersScored ? "form-input error" : "form-input"}
              />
              {errors?.numPlayersScored && (
                <div className="pool-form-error-message">
                  <MdErrorOutline className="pool-form-error-icon" size={16} />
                  <span>{errors.numPlayersScored}</span>
                </div>
              )}
            </div>

            <div className="pool-form-group">
              <label className="form-label" htmlFor="tieBreaker">
                Tie Breaker:
              </label>
              <select
                id="tieBreaker"
                name="tieBreaker"
                value={formState.tieBreaker}
                onChange={handleInputChange}
                disabled={mode === "edit" && !canEdit}
                required
                className={errors?.tieBreaker ? "form-select error" : "form-select"}
              >
                <option value="">Select Tie Breaker</option>
                <option value="Average World Ranking">Average World Ranking</option>
                <option value="Winning Score">Winning Score</option>
              </select>
              {errors?.tieBreaker && (
                <div className="pool-form-error-message">
                  <MdErrorOutline className="pool-form-error-icon" size={16} />
                  <span>{errors.tieBreaker}</span>
                </div>
              )}
            </div>

            <div className="form-checkbox-group">
              <label htmlFor="allMakeCut" className="form-checkbox-label">
                <input 
                  type="checkbox" 
                  id="allMakeCut" 
                  name="allMakeCut" 
                  checked={formState.allMakeCut} 
                  onChange={handleInputChange} 
                  disabled={mode === "edit" && !canEdit} 
                  className="form-checkbox" 
                />
                <span>Require all players to make the cut to be eligible for scoring</span>
              </label>
            </div>
          </form>

          {/* Confirmation Modal */}
          {showConfirmation && (
            <div className="confirmation-modal-overlay">
              <div className="confirmation-modal">
                <div className="confirmation-modal-header">
                  Change Tournament?
                </div>
                <div className="confirmation-modal-content">
                  Changing the tournament will delete any team player selections made for the previous tournament. Are you sure you want to continue?
                </div>
                <div className="confirmation-modal-actions">
                  <button 
                    className="confirmation-modal-button cancel"
                    onClick={handleCancelTournamentChange}
                  >
                    Cancel
                  </button>
                  <button 
                    className="confirmation-modal-button confirm"
                    onClick={handleConfirmTournamentChange}
                  >
                    Change Tournament
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PoolForm;
