import { supabase } from "../ReusableComponents/supabaseClient";
import moment from "moment";
import "moment-timezone";
import { logError } from "../Utilities/errorLogger";

export const fetchInviteLinksByUserId = async (userId) => {
  try {
    // Then get pools where user is invited
    const { data: invitedPools, error: inviteError } = await supabase
      .from("InviteLink")
      .select(
        `
        id,
        pool (
          id,
          name,
          tournament_status,
          current_round,
          round_status,
          tour_fixtures (
            id,
            tour_name
          ),
          tournament_fixture (
            id,
            name,
            start_date
          )
        )
      `
      )
      .eq("person", userId)
      .order("created_date", { ascending: true });

    if (inviteError) throw inviteError;

    // Combine and deduplicate pools
    const allPools = [...invitedPools];

    return allPools;
  } catch (error) {
    await logError({
      filePath: "Pool/PoolModalHelper.js",
      functionName: "fetchInviteLinksByUserId",
      errorType: "Fetch Error",
      errorMessage: error.message,
      stackTrace: error.stack,
      requestData: { userId },
    });
    console.error("Error fetching pools:", error);
    throw error;
  }
};

export const fetchPoolModalData = async (poolId) => {
  try {
    const { data, error } = await supabase
      .from("pool")
      .select(
        `
        id,
        all_players_must_make_cut,
        num_player_used_for_scoring,
        max_players,
        tie_breaker,
        tournament_fixture:tournamentsFixture!pool_tournament_fixture_fkey(
          id,
          name,
          start_date,
          tournament_id,
          season
        ),
        number_of_teams,
        name,
        owner
      `
      )
      .eq("id", poolId)
      .single();

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    await logError({
      filePath: "Pool/PoolModalHelper.js",
      functionName: "fetchPoolModalData",
      errorType: "Fetch Error",
      errorMessage: error.message,
      stackTrace: error.stack,
      requestData: { poolId },
    });
    return null;
  }
};

export const updatePoolDetails = async (formData, poolData) => {
  const { data: tournament, error: tournamentError } = await supabase.from("tournamentsFixture").select("id, name, start_date").eq("id", formData.tournament).single();

  if (tournamentError) {
    return { error: tournamentError };
  }

  const updateData = {
    name: tournament.name,
    all_players_must_make_cut: formData.allMakeCut,
    num_player_used_for_scoring: formData.numPlayersScored,
    max_players: formData.teamSize,
    tie_breaker: formData.tieBreaker,
    tournament_fixture: tournament.id,
  };

  // Only set time_zone to null if tournament has changed
  if (poolData?.tournamentsFixture?.id !== tournament.id) {
    updateData.time_zone = null;
  }

  const { data, error } = await supabase.from("pool").update(updateData).eq("id", poolData.id);

  if (error) {
    throw error;
  }

  return data;
};

export async function fetchTournamentOptions(currentTournamentId = null, tourId = 1) {
  try {
    if (!tourId) {
      tourId = 1;
    }

    const currentYear = moment().year();

    // First, get all tournaments to check their timezones
    const { data: tournaments, error: tournamentsError } = await supabase
      .from("tournamentsFixture")
      .select(
        `
        id,
        name,
        start_date,
        tournament_id,
        season,
        time_zone,
        tour_id
      `
      )
      .eq("tour_id", tourId)
      .order("start_date", { ascending: true });

    if (tournamentsError) {
      console.error("Tournament fetch error:", tournamentsError);
      throw tournamentsError;
    }

    // Filter tournaments based on their specific timezone cutoff times and current calendar year
    const filteredTournaments = tournaments.filter((tournament) => {
      const timezone = tournament.time_zone || "America/Los_Angeles";
      const now = moment().tz(timezone);
      const today5AM = now.clone().startOf("day").add(7, "hours");
      const tournamentDate = moment.tz(tournament.start_date, "UTC").tz(timezone);
      const tournamentYear = tournamentDate.year();

      // If it's before 5 AM today, use yesterday's 5 AM as cutoff
      const cutoffTime = now.isBefore(today5AM) ? today5AM.subtract(1, "day") : today5AM;

      // If tournament has already started (past 5 AM on its start date), exclude it
      // unless it's the current tournament
      if (currentTournamentId && tournament.tournament_id === currentTournamentId) {
        return true;
      }

      // Check if tournament is in the current calendar year and after cutoff time
      return tournamentYear === currentYear && tournamentDate.isAfter(cutoffTime);
    });

    return filteredTournaments || [];
  } catch (error) {
    await logError({
      filePath: "Pool/PoolModalHelper.js",
      functionName: "fetchTournamentOptions",
      errorType: "Fetch Error",
      errorMessage: error.message,
      stackTrace: error.stack,
      requestData: { currentTournamentId, tourId },
    });
    console.error("Error fetching tournament options:", error);
    return [];
  }
}

export function validateFormData(formData) {
  const errors = {};

  if (!formData.tournament) {
    errors.tournament = "Tournament is required";
  }

  if (!formData.tieBreaker) {
    errors.tieBreaker = "Tie Breaker is required";
  }

  if (!formData.tour) {
    errors.tour = "Tour is required";
  }

  const teamSize = parseInt(formData.teamSize, 10);
  const numPlayersScored = parseInt(formData.numPlayersScored, 10);

  if (isNaN(teamSize) || teamSize < 1 || teamSize > 15) {
    errors.teamSize = "Team Size must be between 1 and 15";
  }

  if (isNaN(numPlayersScored) || numPlayersScored < 1 || numPlayersScored > 15) {
    errors.numPlayersScored = "# of Players must be between 1 and 15";
  }

  if (!isNaN(teamSize) && !isNaN(numPlayersScored) && numPlayersScored > teamSize) {
    errors.numPlayersScored = "Must be less or equal to Max Players";
  }

  if (!formData.tieBreaker || formData.tieBreaker === "Select Tie Breaker") {
    errors.tieBreaker = "Tie Breaker is required";
  }

  return errors;
}

const fetchTournamentDetails = async (tournamentId) => {
  const { data: tournament, error: tournamentError } = await supabase.from("tournamentsFixture").select("id, name, start_date, tournament_id").eq("id", tournamentId).single();

  if (tournamentError) {
    console.error("Error fetching tournament:", tournamentError);
    throw new Error(`Failed to fetch tournament: ${tournamentError.message}`);
  }

  if (!tournament) {
    throw new Error("Tournament not found");
  }

  return tournament;
};

const createPoolRecord = async (formData, userId, tournament, currentDate) => {

  const { data: pool, error: poolError } = await supabase
    .from("pool")
    .insert([
      {
        name: tournament.name,
        all_players_must_make_cut: formData.allMakeCut,
        num_player_used_for_scoring: parseInt(formData.numPlayersScored, 10),
        max_players: parseInt(formData.teamSize, 10),
        tie_breaker: formData.tieBreaker,
        tournament_fixture: tournament.id,
        owner: userId,
        created_date: currentDate,
        modified_date: currentDate,
        tour_fixtures: parseInt(formData.tour, 10),
      },
    ])
    .select('id, name, all_players_must_make_cut, num_player_used_for_scoring, max_players, tie_breaker, tournament_fixture, owner, created_date, modified_date, tour_fixtures');

  if (poolError) {
    console.error("Error creating pool:", poolError);
    throw new Error(`Failed to create pool: ${poolError.message}`);
  }

  return pool[0];
};

const createTeamRecord = async (userId, poolId, currentDate) => {
  const { data: team, error: teamError } = await supabase
    .from("team")
    .insert([
      {
        owner: userId,
        pool: poolId,
        created_date: currentDate,
        modified_date: currentDate,
      },
    ])
    .select();

  if (teamError) {
    console.error("Error creating team:", teamError);
    throw new Error(`Failed to create team: ${teamError.message}`);
  }

  if (!team || team.length === 0) {
    throw new Error("Failed to create team - no data returned");
  }

  return team[0];
};

const createInviteLinkRecord = async (poolId, userId, teamId, currentDate) => {
  const { data: inviteLink, error: inviteLinkError } = await supabase
    .from("InviteLink")
    .insert([
      {
        pool: poolId,
        person: userId,
        team: teamId,
        created_date: currentDate,
        modified_date: currentDate,
      },
    ])
    .select();

  if (inviteLinkError) {
    console.error("Error creating invite link:", inviteLinkError);
    throw new Error(`Failed to create invite link: ${inviteLinkError.message}`);
  }

  if (!inviteLink || inviteLink.length === 0) {
    throw new Error("Failed to create invite link - no data returned");
  }

  return inviteLink[0];
};

export async function savePoolAndInvite(formData) {
  try {
    const userId = localStorage.getItem("userId");
    const currentDate = moment().tz("America/Chicago").utc().format();

    // Step 1: Fetch tournament details
    const tournament = await fetchTournamentDetails(formData.tournament);

    // Step 2: Create pool record
    const pool = await createPoolRecord(formData, userId, tournament, currentDate);

    // Step 3: Create team record
    const team = await createTeamRecord(userId, pool.id, currentDate);

    // Step 4: Create invite link record
    const inviteLink = await createInviteLinkRecord(pool.id, userId, team.id, currentDate);

    return {
      success: true,
      pool,
      poolName: tournament.name,
      inviteLink,
    };
  } catch (error) {
    await logError({
      filePath: "Pool/PoolModalHelper.js",
      functionName: "savePoolAndInvite",
      errorType: "Save Error",
      errorMessage: error.message,
      stackTrace: error.stack,
      requestData: { formData },
    });
    console.error("Error in savePoolAndInvite:", error);
    return { error: error.message };
  }
}

export async function fetchInviteLinksByPool(poolId) {
  try {
    const { data, error } = await supabase
      .from("InviteLink")
      .select(
        `
        id,
        email,
        created_date,
        modified_date,
        paid,
        person:person( id, email, full_name),
        pool:pool(id, tournamentsFixture:tournamentsFixture(name, start_date, end_date))
      `
      )
      .eq("pool", poolId);

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    await logError({
      filePath: "Pool/PoolModalHelper.js",
      functionName: "fetchInviteLinksByPool",
      errorType: "Fetch Error",
      errorMessage: error.message,
      stackTrace: error.stack,
      requestData: { poolId },
    });
    return [];
  }
}

export async function updateInviteLinkPaidStatus(linkId, paidStatus) {
  try {
    // First, get the team ID from the InviteLink record
    const { data: inviteLink, error: inviteLinkError } = await supabase
      .from("InviteLink")
      .select("team")
      .eq("id", linkId)
      .single();

    if (inviteLinkError) {
      throw inviteLinkError;
    }

    // Update InviteLink record
    const { error: inviteLinkUpdateError } = await supabase
      .from("InviteLink")
      .update({ paid: paidStatus })
      .eq("id", linkId);

    if (inviteLinkUpdateError) {
      throw inviteLinkUpdateError;
    }

    // Update team record
    const { error: teamUpdateError } = await supabase
      .from("team")
      .update({ paid: paidStatus })
      .eq("id", inviteLink.team);

    if (teamUpdateError) {
      throw teamUpdateError;
    }

    return true;
  } catch (error) {
    await logError({
      filePath: "Pool/PoolModalHelper.js",
      functionName: "updateInviteLinkPaidStatus",
      errorType: "Update Error",
      errorMessage: error.message,
      stackTrace: error.stack,
      requestData: { linkId, paidStatus },
    });
    console.error("Error updating paid status:", error);
    return false;
  }
}

export const resendInviteEmail = async (email, poolId) => {
  try {
    const response = await fetch("/api/email/invitePlayerEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, poolId, isResend: true }),
    });
    return response;
  } catch (error) {
    await logError({
      filePath: "Pool/PoolModalHelper.js",
      functionName: "resendInviteEmail",
      errorType: "Resend Error",
      errorMessage: error.message,
      stackTrace: error.stack,
      requestData: { email, poolId },
    });
    throw error;
  }
};

export const canEditRecord = (pool) => {

  if (!pool?.tournament_fixture?.start_date) {
    console.log('No tournament start date found, allowing edit');
    return true;
  }

  const timezone = pool.time_zone || "America/Chicago";
  const now = moment().tz(timezone);
  
  // Use the database date directly and add 5 hours for tournament start
  const tournamentStart = moment.tz(pool.tournament_fixture.start_date, timezone).add(5, "hours");

  // Allow editing if we're before 5 AM on tournament day
  if (now.isBefore(tournamentStart)) {
    console.log('Before tournament start time (5 AM), allowing edit');
    return true;
  }

  return false;
};
