import React, { useState, useEffect, useCallback } from "react";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import "./InviteToPool.css";
import { logError } from "../Utilities/errorLogger";

const InviteToPool = ({ poolId, onAddEmail, onSendInvites, isSubmitting, onClose }) => {
  const [emails, setEmails] = useState([""]);
  const loggedInUserEmail = localStorage.getItem("email");

  const validateEmails = useCallback(
    (emailList) => {
      const validEmails = emailList.filter((email) => email.trim() !== "");

      if (validEmails.length === 0) {
        throw new Error("Please enter at least one email address");
      }

      if (validEmails.some((email) => email.toLowerCase() === loggedInUserEmail?.toLowerCase())) {
        throw new Error("You cannot invite yourself to the pool");
      }

      return validEmails;
    },
    [loggedInUserEmail]
  );

  const processInvite = useCallback(
    async (email, currentDate) => {
      try {
        const response = await fetch("/api/email/invitePlayerEmail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            poolId,
            currentDate,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || `Failed to invite ${email}`);
        }
      } catch (error) {
        await logError({
          filePath: "Leaderboard/AllLeaderboards.js",
          functionName: "fetchPools",
          errorType: "Pools Fetch Error",
          errorMessage: error.message,
          stackTrace: error.stack,
          requestData: { email, poolId, currentDate },
        });
        throw error;
      }
    },
    [poolId]
  );

  const handleSubmit = useCallback(async () => {
    try {
      // Validate emails
      const validEmails = validateEmails(emails);

      // Get current date once for all operations
      const currentDate = moment().tz("America/Chicago").utc().format();

      // Process all invites
      await Promise.all(validEmails.map((email) => processInvite(email, currentDate)));

      setEmails([""]);

      // Close modal and show success message
      if (onClose) {
        // Close immediately
        onClose();
        // Show success message after a brief delay
        setTimeout(() => {
          toast.success("Invitations sent successfully!", { theme: "dark" });
        }, 100);
      } else {
        // If no onClose provided, just show the success message
        toast.success("Invitations sent successfully!", { theme: "dark" });
      }

      return true;
    } catch (error) {
      await logError({
        filePath: "Leaderboard/AllLeaderboards.js",
        functionName: "fetchPools",
        errorType: "Pools Fetch Error",
        errorMessage: error.message,
        stackTrace: error.stack,
        requestData: { emails, poolId },
      });

      toast.error(error.message || "Failed to send invitations", { theme: "dark" });
      return false;
    }
  }, [emails, validateEmails, processInvite, onClose, poolId]);

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  const addEmailField = useCallback(() => {
    setEmails((prev) => [...prev, ""]);
  }, []);

  const removeEmailField = (index) => {
    if (emails.length > 1) {
      const newEmails = emails.filter((_, i) => i !== index);
      setEmails(newEmails);
    }
  };

  useEffect(() => {
    if (onAddEmail) {
      onAddEmail(addEmailField);
    }
  }, [onAddEmail, addEmailField]);

  useEffect(() => {
    if (onSendInvites) {
      onSendInvites(handleSubmit);
    }
  }, [onSendInvites, handleSubmit]);

  return (
    <div className="invite-form">
      <div className="invite-content">
        <div className="email-input-row">
          <input
            type="email"
            value={emails[0] || ""}
            onChange={(e) => handleEmailChange(0, e.target.value)}
            placeholder="Enter email address"
            className="email-input"
            required
            disabled={isSubmitting}
          />
          <div className="button-spacer">
            {emails.length > 1 && (
              <button type="button" onClick={() => removeEmailField(0)} className="email-remove-button" aria-label="Remove email" disabled={isSubmitting}>
                <IoMdClose size={20} />
              </button>
            )}
          </div>
        </div>

        {emails.slice(1).map((email, index) => (
          <div key={index + 1} className="email-input-row">
            <input
              type="email"
              value={email}
              onChange={(e) => handleEmailChange(index + 1, e.target.value)}
              placeholder="Enter email address"
              className="email-input"
              required
              disabled={isSubmitting}
            />
            <div className="button-spacer">
              <button type="button" onClick={() => removeEmailField(index + 1)} className="email-remove-button" aria-label="Remove email" disabled={isSubmitting}>
                <IoMdClose size={20} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InviteToPool;
