import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import { FaTrophy } from "react-icons/fa";
import { TbGolf } from "react-icons/tb";
import { supabase } from "../ReusableComponents/supabaseClient";
import { PacmanLoader } from "react-spinners";
import moment from "moment";
import "../styles/golfPoolStyles.css";
import "./AllTeamsView.css";
import { logError } from "../Utilities/errorLogger";

const AllTeamsView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const { data: teams, error } = await supabase
          .from("team")
          .select(
            `
            id,
            rank,
            number_of_line_items,
            pool (
              id,
              name,
              max_players,
              tournament_status,
              tournament_fixture (
                name,
                start_date
              )
            ),
            paid,
            number_of_line_items
          `
          )
          .eq("owner", userId)
          .order("pool(id)", { ascending: true });

        if (error) throw error;
        setTeams(teams);
        setLoading(false);
      } catch (error) {
        await logError({
          filePath: "Team/AllTeamsView.js",
          functionName: "fetchTeams",
          errorType: "Error",
          errorMessage: error.message,
          stackTrace: error.stack,
        });
        console.error("Error fetching teams:", error);
        setLoading(false);
      }
    };

    fetchTeams();
  }, [userId]);

  const handleRowClick = (poolId, teamId) => {
    navigate(`/pool/${poolId}/team/${teamId}`);
  };

  const getRankDisplay = (team) => {
    if (team.pool.tournament_status === "Not Started") {
      return (
        <div className="tournament-not-started">
          <TbGolf className="golf-tee-icon" />
          <span>Pending</span>
        </div>
      );
    }
    
    if (team.rank === "MC") {
      return <span className="golf-pools-mc-teams">Cut</span>;
    }

    return (
      <div className="golf-pools-rank">
        {team.rank === "1" && team.pool.tournament_status === "Official" && (
          <FaTrophy className="golf-pools-trophy golf-pools-trophy-1" />
        )}
        <span className="rank-text">{`${team.rank}${getOrdinalSuffix(team.rank)}`}</span>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="golf-pools-card">
        <div className="golf-pools-container">
          <div className="golf-pools-content">
            <div className="golf-pools-loading-container">
              <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderTeamsTable = () => (
    <div className="golf-pools-table-wrapper desktop-only">
      <table className="golf-pools-table">
        <thead>
          <tr>
            <th className="text-center all-teams-pool-number">Pool #</th>
            <th className="text-center all-teams-tournament">Tournament</th>
            <th className="text-center all-teams-date">Date</th>
            <th className="text-center all-teams-position">Position</th>
            <th className="text-center all-teams-players">Players</th>
            <th className="text-center all-teams-paid">Paid</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team) => (
            <tr key={team.id} onClick={() => handleRowClick(team.pool.id, team.id)} className="clickable">
              <td className="all-teams-pool-number">{team.pool.id}</td>
              <td className="all-teams-tournament">{team.pool.tournament_fixture?.name}</td>
              <td className="all-teams-date">{team.pool.tournament_fixture?.start_date ? moment(team.pool.tournament_fixture.start_date).format("MM/DD/YY") : "N/A"}</td>
              <td className="all-teams-position">
                {getRankDisplay(team)}
              </td>
              <td className="all-teams-players">
                <span className={`team-status ${team.number_of_line_items === team.pool.max_players ? "complete" : "incomplete"}`}>
                  {team.number_of_line_items || 0}/{team.pool.max_players || "-"}
                </span>
              </td>
              <td className="all-teams-paid">
                <span className={`team-status ${team.paid ? "complete" : "incomplete"}`}>{team.paid ? "Yes" : "No"}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderTeamsCards = () => (
    <div className="pools-cards-container mobile-only">
      {teams.map((team) => (
        <div key={team.id} className="pool-card" onClick={() => handleRowClick(team.pool.id, team.id)}>
          <div className="pool-card-header">
            <h3>{team.pool.tournament_fixture?.name}</h3>
            {getRankDisplay(team)}
          </div>
          <div className="pool-card-content">
            <div className="pool-card-detail">
              <span className="detail-label">Date:</span>
              <span className="detail-value">{team.pool.tournament_fixture?.start_date ? moment(team.pool.tournament_fixture.start_date).format("MM/DD/YY") : "N/A"}</span>
            </div>
            <div className="pool-card-detail">
              <span className="detail-label">Players:</span>
              <span className={`team-status ${team.number_of_line_items === team.pool.max_players ? "complete" : "incomplete"}`}>
                {team.number_of_line_items || 0}/{team.pool.max_players || "-"}
              </span>
            </div>
            <div className="pool-card-detail">
              <span className="detail-label">Paid:</span>
              <span className={`team-status ${team.paid ? "complete" : "incomplete"}`}>{team.paid ? "Yes" : "No"}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  // Helper function to add ordinal suffix to numbers (1st, 2nd, 3rd, etc.)
  const getOrdinalSuffix = (num) => {
    if (!num) return "";
    const n = parseInt(num);
    if (isNaN(n)) return "";

    if (n % 100 >= 11 && n % 100 <= 13) return "th";

    switch (n % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return (
    <div className="golf-pools-card">
      <div className="golf-pools-container">
        <div className="golf-pools-header">
          <div className="golf-pools-header-row">
            <button onClick={() => navigate(-1)} className="golf-pools-back-link">
              <IoChevronBackOutline /> Back
            </button>
            <h1 className="golf-pools-title">My Teams</h1>
            <div style={{ width: "80px" }}></div>
          </div>
        </div>

        {teams.length > 0 ? (
          <>
            {renderTeamsTable()}
            {renderTeamsCards()}
          </>
        ) : (
          <div className="golf-pools-empty-container">
            <p>No teams available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllTeamsView;
