import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSupabaseUserAuth from "../Utilities/useSupabaseUserAuth";
import { toast, ToastContainer } from "react-toastify";
import BackgroundWrapper from "./HomeBackgroundWrapper";
import "./Login.css";
import { logError } from "../Utilities/errorLogger";

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { completePasswordReset } = useSupabaseUserAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  // Extract token and email from URL parameters
  const rawToken = searchParams.get("token");
  const email = searchParams.get("email");

  // Clean the token - remove any URL parts if present
  const token = rawToken?.includes("?token=") ? rawToken.split("?token=")[1] : rawToken;

  // Validate token and email on component mount
  React.useEffect(() => {
    if (!token || !email) {
      toast.error("Invalid reset link. Please request a new password reset.", {
        theme: "dark",
        position: "top-center",
        autoClose: 5000,
      });
      navigate("/");
    }
  }, [token, email, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when user starts typing
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }

    if (!formData.confirmPassword) {
      errors.confirmPassword = "Please confirm your password";
    } else if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    if (!token || !email) {
      toast.error("Invalid reset link. Please request a new password reset.", {
        theme: "dark",
        position: "top-center",
        autoClose: 5000,
      });
      navigate("/");
      return;
    }

    setIsSubmitting(true);
    try {
      await completePasswordReset(token, email, formData.password);
      toast.success("Password has been reset successfully", {
        theme: "dark",
        position: "top-center",
        autoClose: 5000,
      });
      // Short delay before navigation to ensure toast is visible
      setTimeout(() => {
        navigate("/", { replace: true });
      }, 2000);
    } catch (error) {
      console.error("Error resetting password:", error);
      await logError({
        filePath: "LoginComponents/ResetPassword.js",
        functionName: "handleSubmit",
        errorType: "Password Reset Error",
        errorMessage: error.message,
        stackTrace: error.stack,
        requestData: { email, token },
      });
      toast.error(error.message || "Failed to reset password", {
        theme: "dark",
        position: "top-center",
        autoClose: 5000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="main-container">
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      <BackgroundWrapper>
        <div className="auth-container">
          <div className="auth-form-container">
            <h2 className="form-title">Reset Your Password</h2>
            <form onSubmit={handleSubmit} className="loginForm">
              <div className="form-group">
                <input type="password" name="password" placeholder="New Password" value={formData.password} onChange={handleInputChange} className="inputStyle" disabled={isSubmitting} required />
                {formErrors.password && <div className="error-message-login">{formErrors.password}</div>}
              </div>

              <div className="form-group">
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm New Password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  className="inputStyle"
                  disabled={isSubmitting}
                  required
                />
                {formErrors.confirmPassword && <div className="error-message-login ">{formErrors.confirmPassword}</div>}
              </div>

              <button type="submit" className="submitButton" disabled={isSubmitting}>
                {isSubmitting ? "Resetting Password..." : "Reset Password"}
              </button>

              <button type="button" className="link-button" onClick={() => navigate("/")} disabled={isSubmitting}>
                Back to Login
              </button>
            </form>
          </div>
        </div>
      </BackgroundWrapper>
    </div>
  );
}

export default ResetPassword;
