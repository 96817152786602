// hooks/supabaseUserAuth.js
import { useCallback } from "react";
import { supabase } from "../ReusableComponents/supabaseClient";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";
import bcrypt from "bcryptjs";
import { logError } from "./errorLogger";

const useSupabaseUserAuth = () => {
  const hashPassword = useCallback(async (password) => {
    const salt = await bcrypt.genSalt(10);
    return bcrypt.hash(password, salt);
  }, []);

  // Temporary function to fix password hash
  const fixPasswordHash = useCallback(
    async (email) => {
      try {
        const hashedPassword = await hashPassword("Pass1234");
        const date = moment().tz("America/Chicago").utc().format();

        const { error } = await supabase
          .from("user")
          .update({
            password: hashedPassword,
            modified_date: date,
          })
          .eq("email", email.toLowerCase());

        if (error) {
          console.error("Update error:", error);
          throw new Error(error.message || "Failed to update password hash");
        }

        return true;
      } catch (error) {
        await logError({
          filePath: "Leaderboard/AllLeaderboards.js",
          functionName: "fixPasswordHash",
          errorType: "Password Hash Fix Error",
          errorMessage: error.message,
          stackTrace: error.stack,
          requestData: { email },
        });

        console.error("Error fixing password hash:", error);
        throw error;
      }
    },
    [hashPassword]
  );

  const createUser = useCallback(async (formState) => {
    try {
      const hashedPassword = await hashPassword(formState.password);
      const date = moment().tz("America/Chicago").utc().format();

      const { data: newUser, error: createError } = await supabase
        .from("user")
        .insert([
          {
            first_name: formState.firstName,
            last_name: formState.lastName,
            full_name: `${formState.firstName} ${formState.lastName}`,
            email: formState.email.toLowerCase(),
            password: hashedPassword,
            created_date: date,
            modified_date: date,
          },
        ])
        .select('*')
        .single();

      if (createError) {
        const errorMessage = createError.message || "Error creating user";
        if (createError.code === '23505') { // Unique violation error code
          throw new Error("An account with this email already exists");
        }
        await logError({
          filePath: "Utilities/useSupabaseUserAuth.js",
          functionName: "createUser",
          errorType: "User Creation Error",
          errorMessage: errorMessage,
          stackTrace: createError.stack,
          requestData: { formState },
        });
        throw new Error(errorMessage);
      }

      // Send welcome email
      try {
        await fetch("/api/email/newUserEmail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: `${formState.firstName} ${formState.lastName}`,
            email: formState.email,
          }),
        });
      } catch (emailError) {
        console.error("Failed to send welcome email:", emailError);
        // Don't throw here as user creation was successful
      }

      return newUser;
    } catch (error) {
      throw error;
    }
  }, [hashPassword]);

  const fetchUser = useCallback(async (email) => {
    try {
      const { data, error } = await supabase
        .from("user")
        .select("*")
        .eq("email", email.toLowerCase())
        .single();

      if (error) {
        if (error.code === 'PGRST116') {
          // No rows returned
          return null;
        }
        throw error;
      }

      return data;
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error;
    }
  }, []);

  const initiatePasswordReset = useCallback(async (email) => {
    try {
      // First, verify the user exists
      const { data: users, error: userError } = await supabase.from("user").select("*").eq("email", email.toLowerCase());

      if (userError) {
        throw new Error("Error verifying user account");
      }

      if (!users || users.length === 0) {
        throw new Error("No account found with this email");
      }

      // Generate a reset token and expiry
      const resetToken = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      const resetExpiry = moment().tz("America/Chicago").add(1, "hour").utc().format();
      const date = moment().tz("America/Chicago").utc().format();

      // Update user with reset token and expiry
      const updateResponse = await supabase
        .from("user")
        .update({
          reset_token: resetToken,
          reset_token_expires: resetExpiry,
          modified_date: date,
        })
        .eq("email", email.toLowerCase())
        .select();

      if (updateResponse.error) {
        throw new Error(updateResponse.error.message);
      }

      if (!updateResponse.data || updateResponse.data.length === 0) {
        throw new Error("Failed to update reset token");
      }

      // Use APP_URL from environment if available, otherwise use window.location.origin
      const baseUrl = process.env.REACT_APP_URL || window.location.origin;
      const resetLink = `${baseUrl}/reset-password?token=${resetToken}&email=${encodeURIComponent(email)}`;

      const emailResponse = await fetch("/api/email/resetPasswordEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          resetLink,
        }),
      });

      if (!emailResponse.ok) {
        throw new Error("Failed to send reset email");
      }

      toast.success("Password reset instructions have been sent to your email", { theme: "dark" });
      return true;
    } catch (error) {
      console.error("Error initiating password reset:", error);
      await logError({
        filePath: "Utilities/useSupabaseUserAuth.js",
        functionName: "initiatePasswordReset",
        errorType: "Password Reset Error",
        errorMessage: error.message,
        stackTrace: error.stack,
        requestData: { email },
      });
      toast.error(error.message || "Failed to initiate password reset", { theme: "dark" });
      throw error;
    }
  }, []);

  const completePasswordReset = useCallback(
    async (token, email, newPassword) => {
      try {
        // First, check if the user exists and get their current token
        const { data: users, error: userError } = await supabase.from("user").select("*").eq("email", email.toLowerCase());

        if (userError) {
          console.error("Error fetching user:", userError);
          throw new Error("Error verifying user account");
        }

        if (!users || users.length === 0) {
          console.error("No user found with email:", email);
          throw new Error("No account found with this email address");
        }

        const user = users[0];

        // Verify the reset token
        if (!user.reset_token || user.reset_token !== token) {
          console.error("Token mismatch or not found. User token:", user.reset_token, "Provided token:", token);
          throw new Error("Invalid or expired reset token. Please request a new password reset.");
        }

        // Check token expiration
        const tokenExpiry = moment.utc(user.reset_token_expires);
        const currentTime = moment.utc();

        if (currentTime.isAfter(tokenExpiry)) {
          console.error("Token expired. Expiry:", tokenExpiry.format(), "Current:", currentTime.format());
          throw new Error("Reset token has expired. Please request a new password reset.");
        }

        // Hash new password and update user
        const hashedPassword = await hashPassword(newPassword);
        const date = moment().tz("America/Chicago").utc().format();

        const { error: updateError } = await supabase
          .from("user")
          .update({
            password: hashedPassword,
            reset_token: null, // Clear the token after successful reset
            reset_token_expires: null, // Clear the expiry after successful reset
            modified_date: date,
          })
          .eq("email", email.toLowerCase());

        if (updateError) {
          console.error("Update error:", updateError);
          throw new Error(updateError.message || "Failed to update password");
        }

        // Send confirmation email
        try {
          const emailResponse = await fetch("/api/email/resetPasswordEmail", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email,
              resetLink: "", // Send empty string instead of null
              isConfirmation: true, // Add flag to indicate this is a confirmation email
            }),
          });

          if (!emailResponse.ok) {
            const errorText = await emailResponse.text();
            console.error("Error sending confirmation email:", errorText);
            // Don't throw here as the password is already reset
          }
        } catch (error) {
          await logError({
            filePath: "Utilities/useSupabaseUserAuth.js",
            functionName: "completePasswordReset",
            errorType: "Password Reset Error",
            errorMessage: error.message,
            stackTrace: error.stack,
            requestData: { email, token },
          });
          console.error("Error sending confirmation email:", error);
          // Don't throw here as the password is already reset
        }

        toast.success("Password has been successfully reset", {
          theme: "dark",
          position: "top-center",
          autoClose: 5000,
        });
        return true;
      } catch (error) {
        console.error("Error completing password reset:", error);
        await logError({
          filePath: "Utilities/useSupabaseUserAuth.js",
          functionName: "completePasswordReset",
          errorType: "Password Reset Error",
          errorMessage: error.message,
          stackTrace: error.stack,
          requestData: { email, token },
        });
        toast.error(error.message || "Failed to reset password", {
          theme: "dark",
          position: "top-center",
          autoClose: 5000,
        });
        throw error;
      }
    },
    [hashPassword]
  );

  const verifyPassword = useCallback(async (password, hashedPassword) => {
    try {
      return await bcrypt.compare(password, hashedPassword);
    } catch (error) {
      await logError({
        filePath: "Utilities/useSupabaseUserAuth.js",
        functionName: "verifyPassword",
        errorType: "Password Verification Error",
        errorMessage: error.message,
        stackTrace: error.stack,
        requestData: { password, hashedPassword },
      });
      console.error("Error verifying password:", error);
      return false;
    }
  }, []);

  return { createUser, initiatePasswordReset, completePasswordReset, verifyPassword, fixPasswordHash, fetchUser };
};

export default useSupabaseUserAuth;